import React, { useEffect, useState } from "react";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import { Grid, Box, Button } from "@mui/material";
import CustomTextField from "app/shared-components/Form/CustomTextField";
import CustomAutocomplete from "app/shared-components/Form/CustomAutoComplete";
import CustomDatePicker from "app/shared-components/Form/CustomDatePicker";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import history from "@history";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCurrentStep,
  getReferralDetails,
  getReferralDetailsData,
  selectIsButtonLoading,
  setCurrentStep,
  updateUserReferralDetailsInForm,
  userReferralDetailsInForm,
} from "app/store/application-form/applicationFormSlice";
import { sexs, tenantId, titles } from "src/app/common/constant";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import {
  calculateMinimumDOBForAdult,
  removeLeadingZero,
} from "src/app/services/utils";
import commonPhoneNumberInputProps from "app/shared-components/Form/commonPhoneNumberInputProps";
import moment from "moment";
import { isEmpty } from "lodash";
import { formatDateForForm } from "src/app/services/utils";
import { dateOfBirthValidation, emailValidation, phoneNumberValidation } from "src/app/services/ValidationHelper/validationHelpers";

const ReferralDetails = ({
  financeApplicationId = null,
  isEdit = false,
  setIsDirty = (value) => {},
}) => {
  const { t } = useTranslation("applicationForm");
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [search] = useSearchParams();
  const activeStep = useSelector(getCurrentStep);
  const referralDetails = useSelector(getReferralDetailsData);
  const isLoading = useSelector(selectIsButtonLoading);
  const financeId = search.get("id") || null;
  const userId = search.get("userId") || null;

  const schema = yup.object().shape({
    title: yup.string().required(t("SELECT_FIELD_REQUIRED")),
    other: yup.string().when(["title"], (title, schema) => {
      if (title[0] === "OTHER") {
        return schema.required(
          t("TEXT_FIELD_REQUIRED_VALIDATION", {
            fieldName: t("OTHER"),
          })
        );
      }
      return schema.notRequired();
    }),
    name: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("FULL_NAME") })
      ),
    sex: yup.string().required(t("SELECT_FIELD_REQUIRED")),
    dateOfBirth: dateOfBirthValidation(),
    nationalId: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("NATIONAL_ID") })
      ),
    relationship: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("RELATIONSHIP") })
      ),
    homeMobileNumber: phoneNumberValidation(true), 
    homePhoneNumber: phoneNumberValidation(),
    emailAddress: emailValidation(true),
    houseAddress: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("HOUSE_ADDRESS") })
      ),
    housePostalCode: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("POST_CODE") })
      ),
    houseCity: yup
      .string()
      .required(t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("CITY") })),
    houseState: yup
      .string()
      .required(t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("STATE") })),
  });

  useEffect(() => {
    const idToUse = financeId || financeApplicationId;
    if (idToUse) {
      dispatch(getReferralDetails(idToUse));
    }
  }, [dispatch, financeId, financeApplicationId]);

  const methods = useForm();
  const { handleSubmit, formState, control, reset, getValues, setValue, watch } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

  const { errors, isValid, isDirty } = formState;

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  function onSubmit(model, event) {
    event.preventDefault();

    const { other, ...rest } = model;

    const finalTitle = model.title === "OTHER" ? model.other : model.title;

    const request_data = {
      ...rest,
      tenantId: tenantId,
      financeApplicationId: financeId || financeApplicationId,
      title: finalTitle,
      dateOfBirth:formatDateForForm(model.dateOfBirth)
    };

    if (
      Object.keys(referralDetails).length !== 0 &&
      referralDetails?.isDefault !== true
    ) {
      const Update_data = {
        ...rest,
        id: referralDetails?.id,
        title: finalTitle,
        dateOfBirth:formatDateForForm(model.dateOfBirth)
      };
      dispatch(
        updateUserReferralDetailsInForm(
          Update_data,
          referralDetails?.financeApplicationId,
          isEdit
        )
      );
    } else {
      dispatch(userReferralDetailsInForm(request_data, isEdit));
    }
  }

  useEffect(() => {
    if (!isEmpty(referralDetails)) {
      setValue("name", referralDetails?.name);
      const dateOfBirth = moment(
        referralDetails?.dateOfBirth,
        "DD/MMM/YYYY"
      ).toDate();
      setValue("dateOfBirth", dateOfBirth);
      setValue("sex", referralDetails?.sex);
      setValue("nationalId", referralDetails?.nationalId);
      setValue("homeMobileNumber", referralDetails?.homeMobileNumber);
      setValue("emailAddress", referralDetails?.emailAddress);
      setValue("homePhoneNumber", referralDetails?.homePhoneNumber);
      setValue("relationship", referralDetails?.relationship);
      setValue("houseAddress", referralDetails?.houseAddress);
      setValue("houseState", referralDetails?.houseState);
      setValue("houseCity", referralDetails?.houseCity);
      setValue("housePostalCode", referralDetails?.housePostalCode);

      const title = referralDetails?.title || titles[0].id;
      if (!titles.map((n) => n.id).includes(title)) {
        setValue("title", "OTHER");
        setValue("other", title || "");
      } else {
        setValue("title", title);
        setValue("other", "");
      }
      reset(getValues());
    }
  }, [referralDetails, setValue]);

  const handleBack = () => {
    dispatch(setCurrentStep(activeStep - 1));
    navigate(
      `/application-form/${activeStep - 1}?userId=${userId}&id=${financeId}`
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-10"
        >
          <StyledTypography variant="body1" className="mt-20">
            {t("REFERRAL_DETAILS")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2} className="mb-8">
                  <Controller
                    name="title"
                    control={control}
                    defaultValue={titles[0].id}
                    render={({ field }) => (
                      <CustomAutocomplete
                        label={t("TITLE")}
                        required
                        onChange={(value: any) => {
                          if (value && value.id) {
                            field.onChange(value.id);
                          } else {
                            field.onChange(null);
                          }
                        }}
                        options={titles?.map((item) => ({
                          label: t(item.label),
                          id: item.id,
                        }))}
                        value={field.value || ""}
                        error={errors.title && t(errors.title.message)}
                      />
                    )}
                  />
                </Grid>
                {watch("title") === "OTHER" && (
                  <Grid item xs={12} sm={2}>
                    <Controller
                      name="other"
                      control={control}
                      defaultValue={titles[0].id}
                      render={({ field }) => (
                        <CustomTextField
                          label={t("OTHER")}
                          fullWidth
                          required
                          value={field.value}
                          onChange={field.onChange}
                          error={errors?.other?.message}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={watch("title") === "OTHER" ? 8 : 10}
                  className="mb-8"
                >
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("FULL_NAME")}
                        required
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        error={errors.name && t(errors.name.message)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} className="mb-8">
                  <Controller
                    name="sex"
                    control={control}
                    render={({ field }) => (
                      <CustomAutocomplete
                        label={t("SEX")}
                        required
                        onChange={(value: any) => {
                          if (value && value.id) {
                            field.onChange(value.id);
                          } else {
                            field.onChange(null);
                          }
                        }}
                        value={field.value || ""}
                        options={sexs?.map((item) => ({
                          label: t(item.label),
                          id: item.id,
                        }))}
                        error={errors.sex && t(errors.sex.message)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="mb-8">
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    render={({ field }) => (
                      <CustomDatePicker
                        label={t("DOB")}
                        required
                        maxDate={calculateMinimumDOBForAdult()}
                        onChange={field.onChange}
                        value={field.value}
                        error={
                          errors.dateOfBirth && errors.dateOfBirth.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="nationalId"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("NATIONAL_ID")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        required
                        isUpperCase={false}
                        fullWidth
                        inputProps={{ maxLength: 12 }}
                        error={
                          errors.nationalId && t(errors.nationalId.message)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="relationship"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("RELATIONSHIP")}
                        required
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        error={
                          errors.relationship && t(errors.relationship.message)
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <StyledTypography variant="body1" className="mt-20">
            {t("CONTACT_DETAILS")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="mb-8">
                  <Controller
                    name="homeMobileNumber"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("MOBILE_PHONE")}
                        type="number"
                        required
                        fullWidth
                        value={field.value || ""}
                        InputProps={commonPhoneNumberInputProps}
                        onChange={(value: any) => {
                          field.onChange(removeLeadingZero(value));
                        }}
                        error={
                          errors.homeMobileNumber &&
                          errors.homeMobileNumber.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="mb-8">
                  <Controller
                    name="homePhoneNumber"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("HOME_PHONE")}
                        type="number"
                        onChange={(value: any) => {
                          field.onChange(removeLeadingZero(value));
                        }}
                        value={field.value || ""}
                        fullWidth
                        InputProps={commonPhoneNumberInputProps}
                        error={
                          errors.homePhoneNumber &&
                          errors.homePhoneNumber.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} className="mb-8">
                  <Controller
                    name="emailAddress"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("PERSONAL_EMAIL")}
                        required
                        fullWidth
                        onChange={field.onChange}
                        value={field.value || ""}
                        error={
                          errors.emailAddress && errors.emailAddress.message
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <StyledTypography variant="body1" className="mt-20">
            {t("HOUSE_ADDRESS")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} className="mb-8">
                  <Controller
                    name="houseAddress"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("HOUSE_ADDRESS")}
                        required
                        fullWidth
                        onChange={field.onChange}
                        value={field.value || ""}
                        error={
                          errors.houseAddress && t(errors.houseAddress.message)
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="houseCity"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("CITY")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        required
                        fullWidth
                        error={errors.houseCity && t(errors.houseCity.message)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="houseState"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("STATE")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        required
                        fullWidth
                        error={
                          errors.houseState && t(errors.houseState.message)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="housePostalCode"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("POST_CODE")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        required
                        fullWidth
                        error={
                          errors.housePostalCode &&
                          t(errors.housePostalCode.message)
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box>
            <Box className="flex justify-end items-end gap-20 mt-10">
              {isEdit ? (
                <ColoredSubmitButton
                  className="w-120"
                  disabled={!isValid || !isDirty}
                  text={t("SAVE")}
                  isLoading={isLoading}
                ></ColoredSubmitButton>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    className="capitalize w-120"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {t("PREVIOUS")}
                  </Button>
                  <ColoredSubmitButton
                    className="w-120"
                    disabled={!isValid}
                    text={t("NEXT")}
                    isLoading={isLoading}
                  ></ColoredSubmitButton>
                </>
              )}
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default ReferralDetails;
