import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import _ from "@lodash";
import { Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import withReducer from "app/store/withReducer";
import moment from "moment";
import {
  tenantId,
  jobTypes,
  sexs,
  qualification,
  maritalStatusEnum,
} from "src/app/common/constant";
import { useTranslation } from "react-i18next";
import CustomTextField from "app/shared-components/Form/CustomTextField";
import CustomAutocomplete from "app/shared-components/Form/CustomAutoComplete";
import CustomDatePicker from "app/shared-components/Form/CustomDatePicker";
import userSlice, {
  additionalInfo,
  getMemberUserId,
  getUserId,
  selectIsButtonLoading,
  updateUserAdditionalInfo,
} from "app/store/user/userSlice";
import {
  calculateMinimumDOBForAdult,
  removeLeadingZero,
} from "src/app/services/utils";
import commonPhoneNumberInputProps from "app/shared-components/Form/commonPhoneNumberInputProps";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import { dateOfBirthValidation, emailValidation, phoneNumberValidation } from "src/app/services/ValidationHelper/validationHelpers";

interface FormModel {
  sex: string;
  nationalId: string;
  religion: string;
  dateOfBirth: string;
  maritalStatus: string;
  race: string;
  jobType: string;
  personalEmailAddress: string;
  workEmailAddress: string;
  hpNumber: string;
  jobUniqueCode: string;
  motherFullName: string;
  highestQualification: string;
  otherValueOfHighestQualification: string;
}

function AdditionalInfoForm({
  additionalData,
  isEdit = false,
  setIsDirty = (value) => { },
}) {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const memberId = useSelector(getMemberUserId);
  const userId = useSelector(getUserId);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const isButtonLoading = useSelector(selectIsButtonLoading);

  const schema = yup.object().shape({
    sex: yup.string().required(t("SELECT_FIELD_REQUIRED")),
    jobType: yup.string().required(t("SELECT_FIELD_REQUIRED")),
    nationalId: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("NATIONAL_ID") })
      ),
    religion: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("RELIGION") })
      ),
    maritalStatus: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("MARITAL_STATUS") })
      ),
    motherFullName: yup.string().required(
      t("TEXT_FIELD_REQUIRED_VALIDATION", {
        fieldName: t("MOTHER_FULL_NAME"),
      })
    ),
    race: yup
      .string()
      .required(t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("RACE") })),
    jobUniqueCode: yup.string().when(["jobType"], (jobType, schema) => {
      if (jobType[0] !== "OTHER") {
        return schema.required(
          t("TEXT_FIELD_REQUIRED_VALIDATION", {
            fieldName: t("JOB_UNIQUE_CODE"),
          })
        );
      }
      return schema.notRequired();
    }),
    personalEmailAddress: emailValidation(true),
    workEmailAddress: emailValidation(),
    hpNumber: phoneNumberValidation(), // Ensure 8 to 10 digits
    highestQualification: yup.string().required(
      t("TEXT_FIELD_REQUIRED_VALIDATION", {
        fieldName: t("HIGHEST_QUALIFICATION"),
      })
    ),
    otherValueOfHighestQualification: yup
      .string()
      .when(["highestQualification"], (highestQualification, schema) => {
        if (highestQualification[0] === "Other") {
          return schema.required(
            t("TEXT_FIELD_REQUIRED_VALIDATION", {
              fieldName: t("HIGHEST_QUALIFICATION"),
            })
          );
        }
        return schema.notRequired();
      }),
    dateOfBirth: dateOfBirthValidation(),
  });

  const methods = useForm<FormModel>({
    mode: "onChange",
    resolver: yupResolver<any>(schema),
  });

  const {
    control,
    formState,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    trigger,
  } = methods;
  const { isValid, errors, isDirty } = formState;

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  useEffect(() => {
    // set default values in the form fields when additional info changes
    if (additionalData) {
      // for highestQualification
      const highQualification = additionalData?.highestQualification;
      if (!qualification?.map((n) => n.id).includes(highQualification)) {
        setValue("highestQualification", "Other");
        setValue("otherValueOfHighestQualification", highQualification);
      } else {
        setValue("highestQualification", highQualification);
        setValue("otherValueOfHighestQualification", "");
      }
      setValue("maritalStatus", additionalData.maritalStatus || "");
      setValue("jobUniqueCode", additionalData.jobUniqueCode);
      setValue("hpNumber", additionalData.hpNumber);
      setValue("jobType", additionalData.jobType);
      setValue("nationalId", additionalData.nationalId);
      setValue("personalEmailAddress", additionalData.personalEmailAddress);
      setValue("workEmailAddress", additionalData.workEmailAddress);
      setValue("motherFullName", additionalData.motherFullName);
      setValue("sex", additionalData.sex);
      setValue("race", additionalData.race);
      setValue("religion", additionalData.religion);

      const dateOfBirth: any = moment(
        additionalData.dateOfBirth,
        "DD/MMM/YYYY"
      ).toDate();
      // Set the value of the date picker
      setSelectedDate(dateOfBirth);
      // Also, set the value of the date picker in the form
      setValue("dateOfBirth", dateOfBirth);
    }
  }, [additionalData, setValue, reset, getValues]);

  // Handle change for the date picker
  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    setValue("dateOfBirth", date); // Update the form value
    trigger("dateOfBirth");
    setValue("dateOfBirth", date, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onSubmit = (model: FormModel) => {
    const formattedDateOfBirth = selectedDate
      ? moment(selectedDate).format("YYYY-MM-DD")
      : null;

    const { highestQualification, ...rest } = model;
    let valueOfHighestQualification = highestQualification;

    if (highestQualification === "Other") {
      valueOfHighestQualification = rest.otherValueOfHighestQualification;
    }

    // Check if additional information is not completed or not available
    if (!additionalData?.id) {
      const request_data = {
        ...model,
        tenantId: tenantId,
        userId: memberId || userId,
        dateOfBirth: formattedDateOfBirth, // Use the formatted date for submission
        highestQualification: valueOfHighestQualification,
      };
      dispatch(additionalInfo(request_data, !!memberId));
    } else {
      // If additional information is completed and available, update it
      const formData = {
        ...model,
        id: additionalData?.id,
        dateOfBirth: formattedDateOfBirth,
        highestQualification: valueOfHighestQualification,
      };
      dispatch(updateUserAdditionalInfo(formData));
    }
  };

  const handleNext = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <Box>
      <Paper variant="outlined">
        <FormProvider {...methods}>
          <form
            className="flex flex-col justify-center w-full p-20"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={2}>
              {/* job type select */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="jobType"
                  control={control}
                  render={({ field }) => (
                    <CustomAutocomplete
                      label={t("JOB_TYPE")}
                      required
                      options={jobTypes?.map((item) => ({
                        label: t(item.label),
                        id: item.id,
                      }))}
                      value={field.value || ""}
                      onChange={(value: any) => {
                        if (value && value.id) {
                          field.onChange(value.id);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      error={errors.jobType && t(errors.jobType.message)}
                    />
                  )}
                />
              </Grid>

              {/* job unique code */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="jobUniqueCode"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      type="text"
                      onChange={field.onChange}
                      value={field.value || ""}
                      required={watch("jobType") !== "OTHER"}
                      error={errors?.jobUniqueCode?.message}
                      label={t("JOB_UNIQUE_CODE")}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {/* National id  */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="nationalId"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      type="text"
                      isUpperCase={false}
                      onChange={field.onChange}
                      value={field.value || ""}
                      error={errors?.nationalId?.message}
                      label={t("NATIONAL_ID")}
                      inputProps={{ maxLength: 12 }}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>

              {/* sex select */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="sex"
                  control={control}
                  render={({ field }) => (
                    <CustomAutocomplete
                      label={t("SEX")}
                      value={field.value || ""}
                      required
                      options={sexs?.map((item) => ({
                        label: t(item.label),
                        id: item.id,
                      }))}
                      onChange={(value: any) => {
                        if (value && value.id) {
                          field.onChange(value.id);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      error={errors.sex && t(errors.sex.message)}
                    />
                  )}
                />
              </Grid>

              {/* Date of Birth */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="dateOfBirth"
                  control={control}
                  render={({ field }) => (
                    <CustomDatePicker
                      value={selectedDate}
                      label={t("DATE_OF_BIRTH")}
                      onChange={handleDateChange}
                      maxDate={calculateMinimumDOBForAdult()}
                      required
                      error={
                        errors.dateOfBirth && (errors.dateOfBirth.message)
                      }
                    />
                  )}
                />
              </Grid>

              {/* religion */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="religion"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      type="text"
                      onChange={field.onChange}
                      value={field.value || ""}
                      error={errors?.religion?.message}
                      label={t("RELIGION")}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>

              {/* mother full name  */}
              <Grid item xs={12} sm={6} md={8}>
                <Controller
                  name="motherFullName"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      type="text"
                      onChange={field.onChange}
                      value={field.value || ""}
                      error={errors?.motherFullName?.message}
                      label={t("MOTHER_FULL_NAME")}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>

              {/* race */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="race"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      type="text"
                      onChange={field.onChange}
                      value={field.value || ""}
                      error={errors?.race?.message}
                      label={t("RACE")}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>

              {/* personal email address */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="personalEmailAddress"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      type="text"
                      onChange={field.onChange}
                      value={field.value || ""}
                      error={errors?.personalEmailAddress?.message}
                      label={t("PERSONAL_EMAIL")}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>

              {/* work email address   */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="workEmailAddress"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      type="text"
                      onChange={field.onChange}
                      value={field.value || ""}
                      error={errors?.workEmailAddress?.message}
                      label={t("WORK_EMAIL")}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {/* home phone number */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="hpNumber"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      type="number"
                      onChange={(value: any) => {
                        field.onChange(removeLeadingZero(value));
                      }}
                      value={field.value || ""}
                      error={errors?.hpNumber?.message}
                      label={t("HOME_PHONE_NUMBER")}
                      fullWidth
                      InputProps={commonPhoneNumberInputProps}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="maritalStatus"
                  control={control}
                  render={({ field }) => (
                    <CustomAutocomplete
                      label={t("MARITAL_STATUS")}
                      onChange={(value: any) => {
                        if (value && value.id) {
                          field.onChange(value.id);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      value={field.value || ""}
                      options={maritalStatusEnum?.map((item) => ({
                        label: t(item.label),
                        id: item.id,
                      }))}
                      required
                      error={
                        errors.maritalStatus && t(errors.maritalStatus.message)
                      }
                    />
                  )}
                />
              </Grid>
              {/* highest Qualification */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="highestQualification"
                  control={control}
                  render={({ field }) => (
                    <CustomAutocomplete
                      type="text"
                      options={qualification?.map((item) => ({
                        label: t(item.label),
                        id: item.id,
                      }))}
                      onChange={(value: any) => {
                        if (value && value.id) {
                          field.onChange(value.id);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      value={field.value || ""}
                      error={errors?.highestQualification?.message}
                      label={t("HIGHEST_QUALIFICATION")}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
              {watch("highestQualification") === "Other" && (
                <Grid item xs={12} sm={6} md={4} className="mb-8">
                  <Controller
                    name="otherValueOfHighestQualification"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("OTHER")}
                        required
                        fullWidth
                        value={field.value || ""}
                        onChange={field.onChange}
                        error={
                          errors.otherValueOfHighestQualification &&
                          t(errors.otherValueOfHighestQualification.message)
                        }
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>

            {/* Navigation Buttons */}
            <Box
              sx={{ mt: 2 }}
              className={additionalData && isEdit ? "text-right" : ""}
            >
              <div>
                <ColoredSubmitButton
                  onClick={handleNext}
                  disabled={!isValid || isButtonLoading || !isDirty}
                  isLoading={isButtonLoading}
                  text={
                    !additionalData && !isEdit
                      ? t("SAVE_AND_CONTINUE")
                      : t("SAVE")
                  }
                />
              </div>
            </Box>
          </form>
        </FormProvider>
      </Paper>
    </Box>
  );
}

export default withReducer("user", userSlice)(AdditionalInfoForm);
