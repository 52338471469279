import axios, { AxiosResponse } from 'axios';
const apiService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwt_access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiService.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (
      (error.response.status === 401 || error.response.status === 440) &&
      error.config &&
      !error.config.__isRetryRequest
    ) {
      // if you ever get an unauthorized response, logout the user
      return tokenRefresh().then((res: any) => {
        const config = { ...error.config };
        config.headers.Authorization = `Bearer ${res.access_token}`;
        return axios
          .request(config)
          .then((response) => {
            return response.data;
          })
          .catch((err) => {
            setSession(null, null);
            window.location.assign('/sign-in'); // Fix: Use window.location.assign instead of direct assignment
            return Promise.reject(err);
          });
      });
    }
    if (error && error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
);

export const tokenRefresh = () => {
  return new Promise((resolve, reject) => {
    api
      .post(`${process.env.REACT_APP_API_URL}/v1/User/RefreshToken`, {
        refreshToken: window.localStorage.getItem('refresh_token'),
      })
      .then((res: any) => {
        const response = {
          access_token: res.token, // changed jwtToken to token
          refresh_token: res.refreshToken,
          user: {
            // from: 'custom-db',
            role: 'user',
            data: {
              displayName: `${res.user.fullName}`, // changed
              email: res.user.email, // changed
              // photoURL: 'assets/images/avatars/brian-hughes.jpg',
            },
          },
        };
        if (response.user) {
          setSession(response.access_token, response.refresh_token);
          resolve(response);
        } else {
          // this.logout();
          reject(new Error('Failed to login with token.'));
        }
      })
      .catch((error) => {
        console.error(error);
        setSession(null, null);
        window.location.assign('/sign-in');
        reject(new Error('Something went wrong.'));
      });
  });
};

export const setSession = (accessToken: string, refreshToken?: string) => {
  if (accessToken) {
    localStorage.setItem('jwt_access_token', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('jwt_access_token');
    localStorage.removeItem('refresh_token');
    delete axios.defaults.headers.common.Authorization;
  }

  if (refreshToken) {
    localStorage.setItem('refresh_token', refreshToken);
  }
};

const api = {
  get: (url: string, data?: Record<string, unknown>) => apiService.get(url, data),
  post: (url: string, data?: Record<string, unknown>, config?: any) => apiService.post(url, data, config),
  put: (url: string, data: Record<string, unknown>) => apiService.put(url, data),
  delete: (url: string) => apiService.delete(url),
};

export default api;
