import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { enGB } from "date-fns/locale";
import { styled } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextFieldVariants } from '@mui/material/TextField';


interface CustomDatePickerProps {
  label: string;
  onChange: (date: Date | null) => void;
  variant?: TextFieldVariants;
  error?: string;
  required?: boolean;
  value?: any;
  defaultValue?: any;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
}

const StyledDatePicker = styled(DatePicker)<CustomDatePickerProps>(
  ({ theme }) => ({
    width: "100%",
    "& .MuiSvgIcon-root": {
      color: theme.palette.secondary.main,
    },
  }
));

const CustomDatePicker = ({ label = "Date", onChange,defaultValue, variant='filled',error,required = false,value = null,maxDate,minDate, disabled}: CustomDatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <StyledDatePicker
        label={label}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        maxDate={maxDate}
        minDate={minDate}
        disabled={disabled}
        slotProps={{
          textField: {
            variant: variant,
            error: !!error, 
            helperText: error,
            required: required
          },
          actionBar: {
            actions: ['clear', "cancel"],
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
