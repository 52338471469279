import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import api from "src/app/services/apiService";

interface NotificationState {
  unreadNotification: any[];
  allNotification: any[];
  notificationListPagination: {
    pageIndex: number;
    pageSize: number;
    totalCount: number;
  };
  loading: {
    notificationListLoading: boolean;
    isDeleteDialogOpen: boolean;
    isDeleteOneCardOpen: boolean;
    isDeleteAllDialogOpen: boolean;
    isNotificationRedirection: boolean;
  };
}

export const getNotification =
  (isRead = true) =>
  async (dispatch, getState) => {
    isRead && dispatch(setNotificationListLoading(true));
    try {
      const pagination = getNotificationListPaginationData(getState());
      const existingNotification = getAllNotificationDetail(getState());

      const queryParams = [
        pagination.pageSize && `PageSize=${pagination.pageSize}`,
        pagination.pageIndex && `PageIndex=${pagination.pageIndex + 1}`,
      ]
        .filter((param) => param)
        .join("&");

      const condition = !isRead ? `isRead=${isRead}` : queryParams;

      const response: any = await api.get(
        `/v1/UserNotification/GetUserNotificationList?${condition}`
      );
      if (response && response.result) {
        if (isRead === false) {
          dispatch(setNotificationDetail(response.result.items));
        } else {
          const totalNotification = existingNotification
            ? [...existingNotification, ...response.result.items]
            : response.result.items;

          dispatch(
            setNotificationListPagination({
              pageIndex: response.result.page - 1,
              totalCount: response.result.totalCount,
              pageSize: response.result.pageSize,
            })
          );
          dispatch(setAllNotificationDetail(totalNotification));
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(showMessage({ message: error.message, variant: "error" }));
    } finally {
      dispatch(setNotificationListLoading(false));
    }
  };

export const markAsRead =
  (notificationId = "") =>
  async (dispatch) => {
    try {
      const response = await api.put(
        `/v1/UserNotification/MarkNotificationAsRead`,
        notificationId ? { id: notificationId } : {}
      );
      dispatch(getNotification(false));
      dispatch(markNotificationAsRead(notificationId));
    } catch (error) {
      console.error(error);
      dispatch(showMessage({ message: error.message, variant: "error" }));
    }
  };
export const deleteNotification =
  (notificationId = "") =>
  async (dispatch) => {
    try {
      const condition = notificationId ? `?id=${notificationId}` : ``;
      const response = await api.delete(`v1/UserNotification${condition}`);
      if (notificationId) {
        dispatch(removeNotificationFromState(notificationId));
        dispatch(
          showMessage({
            message: "Notification Deleted successfully",
            variant: "success",
          })
        );
        dispatch(setIsDeleteDialogOpen(false));
      } else {
        dispatch(removeAllNotificationsFromState());
        dispatch(
          showMessage({
            message: "All Notification Deleted  successfully",
            variant: "success",
          })
        );
        dispatch(setIsDeleteAllDialogOpen(false));
      }
      dispatch(getNotification(false));
    } catch (error) {
      console.error(error);
      dispatch(showMessage({ message: error.message, variant: "error" }));
    }
  };
// Actions

export const getNotificationDetail = (state) =>
  state.notification.unreadNotification;

export const getAllNotificationDetail = (state) =>
  state.notification.allNotification;

export const getNotificationListPaginationData = (state) =>
  state.notification.notificationListPagination;

const initialState: NotificationState = {
  unreadNotification: [],
  allNotification: [],
  notificationListPagination: {
    pageIndex: 0,
    pageSize: 20,
    totalCount: 0,
  },
  loading: {
    isDeleteDialogOpen: false,
    isDeleteOneCardOpen: false,
    isDeleteAllDialogOpen: false,
    isNotificationRedirection: false,
    notificationListLoading: false,
  },
};

// Loading selectors start
const setLoadingState = (key, value) => async (dispatch) => {
  dispatch(setLoading({ key, value }));
};
export const setNotificationListLoading = (value) =>
  setLoadingState("notificationListLoading", value);
export const setIsDeleteDialogOpen = (value) =>
  setLoadingState("isDeleteDialogOpen", value);
export const setIsDeleteOneCardDialogOpen = (value) =>
  setLoadingState("isDeleteOneCardOpen", value);
export const setIsDeleteAllDialogOpen = (value) =>
  setLoadingState("isDeleteAllDialogOpen", value);
export const setIsNotificationRedirection = (value) =>
  setLoadingState("isNotificationRedirection", value);

export const getLoadingStateFactory = (loader) => (state) => {
  return state.notification.loading[loader] || false;
};

export const getNotificationListLoading = getLoadingStateFactory(
  "notificationListLoading"
);
export const getIsDeleteDialog = getLoadingStateFactory("isDeleteDialogOpen");
export const getIsDeleteOneCardDialog = getLoadingStateFactory(
  "isDeleteOneCardOpen"
);
export const getIsDeleteAllDialog = getLoadingStateFactory(
  "isDeleteAllDialogOpen"
);
export const getIsNotificationRedirection = getLoadingStateFactory(
  "isNotificationRedirection"
);
// Loading selectors end

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationDetail: (state, action: PayloadAction<any>) => {
      state.unreadNotification = action.payload;
    },
    setNotificationListPagination: (state, action: PayloadAction<any>) => {
      state.notificationListPagination = {
        ...state.notificationListPagination,
        ...action.payload,
      };
    },
    setAllNotificationDetail: (state, action: PayloadAction<any>) => {
      state.allNotification = action.payload;
    },
    markNotificationAsRead: (state, action) => {
      const notificationId = action.payload;
      const notification = state.allNotification.find(
        (n) => n.id === notificationId
      );
      if (notification) {
        notification.isRead = true;
      }
    },
    removeNotificationFromState: (state, action) => {
      state.allNotification = state.allNotification.filter(
        (notification) => notification.id !== action.payload
      );
    },
    removeAllNotificationsFromState: (state) => {
      state.allNotification = [];
    },
    setLoading: (state, action) => {
      const { key, value } = action.payload;
      state.loading = {
        ...state.loading,
        [key]: value,
      };
    },
    resetNotification: (state: any) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setNotificationDetail,
  setAllNotificationDetail,
  resetNotification,
  setNotificationListPagination,
  removeNotificationFromState,
  removeAllNotificationsFromState,
  markNotificationAsRead,
  setLoading,
} = notificationSlice.actions;

export default notificationSlice;
