import {
  Dialog,
  Button,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { termsAndConditionsContent } from "../../terms-condition/TermsAndConditions";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import { useTranslation } from "react-i18next";
import CustomCheckbox from "app/shared-components/Form/CustomCheckbox";

const ConfirmTermsAndConditions = ({ open, onClose, onAgree }) => {
  const { t } = useTranslation("signInPage");
  const [isAgreed, setIsAgreed] = useState(false);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
    setIsAgreed(false);
  };

  const handleAgree = () => {
    onAgree();
    setIsAgreed(false);
  };

  const handleCheckboxChange = () => {
    setIsAgreed((pre) => !pre);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Box p={2}>
        <Box className="top-0 sticky z-10">
          <StyledTypography variant="h5" className="ml-10">
            {t("TERMS_AND_CONDITION")}
          </StyledTypography>
        </Box>
        <Box className="!overflow-y-auto !max-h-320 p-10">
          {termsAndConditionsContent()}
        </Box>
        <Box className="mt-10 ml-10">
          <CustomCheckbox
            onChange={handleCheckboxChange}
            label={t("I_AGREE_TO_THE_TERMS_AND_CONDITIONS")}
            checked={isAgreed}
          />
        </Box>
        <Box className="flex justify-end gap-10 pt-10">
          <Button
             onClick={(event) => handleClose(event, "buttonClick")}
            color="secondary"
            className="mr-5 capitalize"
          >
            {t("CANCEL")}
          </Button>
          <ColoredSubmitButton
            onClick={handleAgree}
            disabled={!isAgreed}
            text={t("AGREE")}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmTermsAndConditions;
