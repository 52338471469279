import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteNotification, getIsDeleteDialog, getNotificationDetail, markAsRead, setIsDeleteAllDialogOpen, setIsDeleteDialogOpen, setIsNotificationRedirection } from 'src/app/main/notification/store/notificationSlice';
import history from '@history';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Badge from '@mui/material/Badge';
import { Box, Card, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ConfirmDialog from 'app/shared-components/Dialog/confirmDialog';
import { formatDistanceToNow } from 'date-fns';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { selectUser } from 'app/store/user/userSlice';
import { hasPermission, notificationRedirect } from 'src/app/services/utils';
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import moment from 'moment';

function Notification() {
    const { t } = useTranslation("Notification");
    const dispatch : any = useDispatch();
	const user = useSelector(selectUser);
    const notificationDetail = useSelector(getNotificationDetail);
	const isDeleteDialogOpen = useSelector(getIsDeleteDialog);
    const [notification, setNotificationDialog] = useState<HTMLElement | null>(null);
	const [notificationId,setNotificationId]=useState("");

	const isPermissionDeleteNotification = hasPermission(FeaturePermissions.UserNotification.Delete)
    const isPermissionToReadNotification = hasPermission(FeaturePermissions.UserNotification.MarkAsRead)

    const userNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
        setNotificationDialog(event.currentTarget);
    };
    const userNotificationClose = () => {
        setNotificationDialog(null);
    };
	const handleCLickShow = () =>{
		history.push("/notifications");
		setNotificationDialog(null);
	}
	const handleDeleteOpen=(id)=>{
		setNotificationId(id)
		dispatch(setIsDeleteDialogOpen(true));
	}
	const handleDeleteClose = () => {
		dispatch(setIsDeleteDialogOpen(false));
	};
	const confirmDeleteDialog = () => {
		setIsDeleteDialogOpen(false);
		dispatch(deleteNotification(notificationId));
	};
	const handleMarkAllAsRead = () => {
		dispatch(markAsRead())
	}
	const handleMarkAsRead = (notification) =>{
		if(isPermissionToReadNotification){
			dispatch(markAsRead(notification.id))
		}
		notificationRedirect(notification);
		const meta = notification.meta;
  		if (meta) {
			if(meta.event === "ExternaleRemark"){
				dispatch(setIsNotificationRedirection(true))
			}
		}
		userNotificationClose();
	}

    return (
        <>
		{(!user.role || user.role.length !== 0) &&
            <Button
                className="min-h-40 min-w-40 p-0 mx-5 md:py-6"
                onClick={userNotificationClick}
                color="inherit"
            >
                <div className="mx-4 " onClick={userNotificationClick}>
                    <Badge badgeContent={notificationDetail.length} color="secondary">
                        <NotificationsNoneIcon />
                    </Badge>
                </div>

            </Button>
		}
            <Popover
                open={Boolean(notification)}
                anchorEl={notification}
                onClose={userNotificationClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                classes={{
                    paper: 'my-8 rounded-[15px]'
                }}
            >
                <Box className='min-h-[50vh] h-[max-content] sm:max-w-[35vh] flex bg-grey-200'>
                    <div className=' flex flex-col flex-grow content-between bg-white '>
                        <div className='p-20 flex items-center justify-between border-b-2 gap-5' >
                            <Typography variant='h5' className='font-700'>
                                {t("NOTIFICATION")}
                            </Typography>
							{
							isPermissionToReadNotification && notificationDetail && notificationDetail.length > 0 && 
									<Typography variant='subtitle2' className='cursor-pointer' onClick={handleMarkAllAsRead} >
										<u>{t("MARK_ALL_AS_READ")}</u>
									</Typography>
							}
                        </div>
						{
							notificationDetail && notificationDetail.length > 0 ? (
								<div className='w-full flex flex-col content-between gap-6 p-10 bg-grey-200 flex-grow'>
									{
										notificationDetail.slice(0 , 5).map((notification,index)=>(
											<Card  
												className={`relative flex min-h-64 w-full items-center space-x-8 rounded-16 p-20 shadow`}
												elevation={2}
												component='div'
												role='button'
												key={index}
												onClick={() => handleMarkAsRead(notification)}
											>
												<Box
													sx={{ backgroundColor: 'background.default' }}
													className="mr-12 flex h-32 w-32 shrink-0 items-center justify-center rounded-full"
												>
													<Badge className='text-xl' color="secondary" variant="dot" anchorOrigin={{vertical: 'bottom',horizontal: 'right',}}>
														<FuseSvgIcon
															className="opacity-75"
															color="inherit"
														>
															heroicons-solid:star
														</FuseSvgIcon>
													</Badge>
												</Box>
												<div className="flex flex-auto flex-col">
													{notification.title && <Typography className="line-clamp-1 font-semibold">{notification.title}</Typography>}

													{notification.description && (
														<div
															className="text-justify mr-5"
															// eslint-disable-next-line react/no-danger
															dangerouslySetInnerHTML={{ __html: notification.description }}
														/>
													)}

													{notification.lastModified && (
														<Typography
															className="mt-8 text-sm leading-none "
															color="text.secondary"
														>
															{formatDistanceToNow(moment(notification.lastModified, 'DD/MMM/YYYY HH:mm:ss').toDate(), { addSuffix: true })}
														</Typography>
													)}
												</div>
											{isPermissionDeleteNotification &&
												<IconButton
												disableRipple
												className="absolute right-0 top-0 p-8"
												color="inherit"
												size="small"
												onClick={(event) => {event.stopPropagation(); handleDeleteOpen(notification.id)} }
												>
													<FuseSvgIcon
														size={18}
														className="opacity-75"
														color="inherit"
														>
														heroicons-solid:x
													</FuseSvgIcon>
												</IconButton>
											}		
											</Card>
										))
									}
								</div>
							):(
								<div className="flex flex-col items-center bg-grey-200 justify-center flex-grow p-16 gap-8">
									<NotificationsOffIcon color='disabled' className='text-9xl'/>
									<Typography
										className="text-center  text-18"
										color="text.secondary"
									>
										{t("THERE_ARE_NO_NOTIFICATION_FOR_NOW")}
									</Typography>
								</div>
							) 
						}
						<div className=' flex justify-center pb-10 bg-grey-200 text-center '>
							<Button className='w-max capitalize' onClick={handleCLickShow}>
								{t("SHOW_MORE")}
							</Button>	
						</div>
                    </div>
                </Box>
            </Popover>
			<ConfirmDialog
          		open={isDeleteDialogOpen}
          		title={t("ARE_YOU_SURE_TO_DELETE")}
          		handleConfirm={confirmDeleteDialog}
          		handleClose={handleDeleteClose}
          		cancelText={t("CANCEL")}
          		okText={t("OK")}
        	/>
        </>
    );
}

export default Notification;
