import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Typography, Button, Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  changeLanguage,
  selectLanguages,
} from "app/store/i18nSlice";

const schema = yup.object().shape({
  language: yup.string(),
});

const Language = ({ open, onClose }) => {
  const dispatch: any = useDispatch();
  const languages = useSelector(selectLanguages);

  const { control, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const handleLanguageChange = (languageId) => {
    dispatch(changeLanguage(languageId));
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box className="flex flex-col p-20 max-w-256 mx-40 my-20 rounded-6">
        <Typography variant="h6" className="mb-5 text-center">
          Select Language
        </Typography>
        <Box className="mx-auto flex flex-wrap justify-center">
          <Controller
            name="language"
            control={control}
            render={({ field }) => (
              <>
                <Box className="flex flex-col gap-10 mt-10">
                  {languages?.map((lng) => (
                    <Button
                      key={lng.id}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleLanguageChange(lng.id)}
                      className="m-2 border border-black py-6 px-20 hover:border-blue-900"
                    >
                      <Box className="flex gap-10 items-center">
                        <img
                          src={`assets/images/flags/${lng.flag}.svg`}
                          alt={lng.title}
                          className="w-16 h-16 mr-5"
                        />
                        <Typography
                          variant="body1"
                          className="text-black capitalize"
                        >
                          {lng.title}
                        </Typography>
                      </Box>
                    </Button>
                  ))}
                </Box>
              </>
            )}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default Language;
