import React, { useEffect, useState, useCallback } from "react";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import { Grid, Box, Button } from "@mui/material";
import CustomTextField from "app/shared-components/Form/CustomTextField";
import CustomAutocomplete from "app/shared-components/Form/CustomAutoComplete";
import CustomDatePicker from "app/shared-components/Form/CustomDatePicker";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCurrentStep,
  getUserDetailInFinanceForm,
  setCurrentStep,
  userAdditionalInfoInForm,
  getUserAdditionalInfoDataInFinanceForm,
  updateUserAdditionalInfoDetailsInForm,
  selectIsButtonLoading,
} from "app/store/application-form/applicationFormSlice";
import {
  jobTypes,
  qualification,
  sexs,
  tenantId,
  titles,
  maritalStatusEnum,
} from "src/app/common/constant";
import moment from "moment";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import {
  calculateMinimumDOBForAdult,
  removeLeadingZero,
} from "src/app/services/utils";
import {
  getMemberDetail,
  setMemberData,
} from "app/store/user/userSlice";
import commonPhoneNumberInputProps from "app/shared-components/Form/commonPhoneNumberInputProps";
import { isEmpty } from "lodash";
import { dateOfBirthValidation, emailValidation, phoneNumberValidation } from "src/app/services/ValidationHelper/validationHelpers";

export default function PersonalInformation({
  financeApplicationId = null,
  financeUserId = null,
  isEdit = false,
  setIsDirty = (value) => {},
}) {
  const { t } = useTranslation("applicationForm");
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const dispatch: any = useDispatch();
  const activeStep = useSelector(getCurrentStep);
  const userData = useSelector(getUserAdditionalInfoDataInFinanceForm);
  const financeId = search.get("id") || null;
  const userId = search.get("userId") || financeUserId;
  const isLoading = useSelector(selectIsButtonLoading);
  const memberDetail = useSelector(getMemberDetail);

  const schema = yup.object().shape({
    title: yup.string().required(t("SELECT_FIELD_REQUIRED")),
    other: yup.string().when(["title"], (title, schema) => {
      if (title[0] === "OTHER") {
        return schema.required(
          t("TEXT_FIELD_REQUIRED_VALIDATION", {
            fieldName: t("OTHER"),
          })
        );
      }
      return schema.notRequired();
    }),
    maritalStatus: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("MARITAL_STATUS") })
      ),
    fullName: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("FULL_NAME") })
      ),
    sex: yup.string().required(t("SELECT_FIELD_REQUIRED")),
    dateOfBirth: dateOfBirthValidation(),
    nationalId: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("NATIONAL_ID") })
      ),
    religion: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("RELIGION") })
      ),
    jobType: yup.string().required(t("SELECT_FIELD_REQUIRED")),
    jobUniqueCode: yup.string().when(["jobType"], (jobType, schema) => {
      if (jobType[0] === "ARMY" || jobType[0] === "POLICE") {
        return schema.required(
          t("TEXT_FIELD_REQUIRED_VALIDATION", {
            fieldName: t("JOB_UNIQUE_CODE"),
          })
        );
      }
      return schema.notRequired();
    }),
    otherId: yup.string().nullable(),
    race: yup
      .string()
      .required(t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("RACE") })),
    motherFullName: yup.string().required(
      t("TEXT_FIELD_REQUIRED_VALIDATION", {
        fieldName: t("MOTHER_FULL_NAME"),
      })
    ),
    membershipNumber: yup.string(),
    phoneNumber: phoneNumberValidation(true),
    hpNumber: phoneNumberValidation(),
    personalEmailAddress: emailValidation(true),
    workEmailAddress: emailValidation(),
    highestQualification: yup.string().required(
      t("TEXT_FIELD_REQUIRED_VALIDATION", {
        fieldName: t("HIGHEST_QUALIFICATION"),
      })
    ),
    otherValueOfHighestQualification: yup
      .string()
      .when(["highestQualification"], (highestQualification, schema) => {
        if (highestQualification[0] === "Other") {
          return schema.required(
            t("TEXT_FIELD_REQUIRED_VALIDATION", {
              fieldName: t("HIGHEST_QUALIFICATION"),
            })
          );
        }
        return schema.notRequired();
      }),
  });

  useEffect(() => {
    return () => {
      dispatch(setMemberData(null));
    };
  }, [dispatch]);

  useEffect(() => {
    const idToUse = financeId || financeApplicationId;
    if (idToUse) {
      dispatch(getUserDetailInFinanceForm(idToUse, userId));
    }
  }, [financeId, dispatch, financeApplicationId]);

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState,
    control,
    reset,
    setValue,
    getValues,
    watch,
  } = methods;
  const { errors, isValid, isDirty } = formState;
  const selectedDate = watch("dateOfBirth");

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  useEffect(() => {
    if (!isEmpty(userData)) {
      setValue("maritalStatus", userData.maritalStatus || "");
      setValue("fullName", userData.fullName || ""); // Using conditional check
      setValue("sex", userData?.sex);
      setValue("nationalId", userData?.nationalId);
      setValue("otherId", userData?.otherId);
      setValue("religion", userData?.religion);
      setValue("race", userData?.race);
      setValue("motherFullName", userData?.motherFullName);
      setValue("hpNumber", userData?.hpNumber);
      setValue("phoneNumber", userData?.phoneNumber);
      setValue("personalEmailAddress", userData?.personalEmailAddress);
      setValue("workEmailAddress", userData?.workEmailAddress);
      setValue("jobType", userData?.jobType);
      setValue("jobUniqueCode", userData?.jobUniqueCode);
      setValue("membershipNumber", userData?.membershipNumber);

      const dateOfBirth: any = moment(
        userData?.dateOfBirth,
        "DD/MMM/YYYY"
      ).toDate();
      setValue("dateOfBirth", dateOfBirth);

      // for highestQualification
      const highQualification = userData?.highestQualification || "";
      if (!qualification?.map((n) => n.id).includes(highQualification)) {
        setValue("highestQualification", "Other");
        setValue("otherValueOfHighestQualification", highQualification || "");
      } else {
        setValue("highestQualification", highQualification);
        setValue("otherValueOfHighestQualification", "");
      }

      const title = userData?.title || titles[0].id;
      if (!titles.map((n) => n.id).includes(title)) {
        setValue("title", "OTHER");
        setValue("other", title || "");
      } else {
        setValue("title", title);
        setValue("other", "");
      }
      reset(getValues());
    } else {
      setValue("phoneNumber", memberDetail?.phoneNumber);
      setValue("personalEmailAddress", memberDetail?.email);
    }
  }, [userData, setValue, memberDetail]);

  function onSubmit(model, event) {
    event.preventDefault();

    const {
      highestQualification,
      other,
      otherValueOfHighestQualification,
      ...rest
    } = model;
    let valueOfHighestQualification = highestQualification;
    const finalTitle = model.title === "OTHER" ? model.other : model.title;

    if (highestQualification === "Other") {
      valueOfHighestQualification = otherValueOfHighestQualification;
    }

    const formattedDateOfBirth = selectedDate
      ? moment(selectedDate).format("YYYY-MM-DD")
      : null;

    const request_data = {
      ...rest,
      financeApplicationId: financeId || financeApplicationId,
      tenantId: tenantId,
      dateOfBirth: formattedDateOfBirth,
      highestQualification: valueOfHighestQualification,
      title: finalTitle,
    };
    const Update_data = {
      ...rest,
      id: userData?.id,
      dateOfBirth: formattedDateOfBirth,
      highestQualification: valueOfHighestQualification,
      title: finalTitle,
    };

    if (Object.keys(userData).length !== 0 && userData?.isDefault !== true) {
      dispatch(
        updateUserAdditionalInfoDetailsInForm(
          Update_data,
          userData?.financeApplicationId,
          isEdit
        )
      );
    } else {
      dispatch(userAdditionalInfoInForm(request_data, isEdit));
    }
  }

  const handleBack = () => {
    dispatch(setCurrentStep(activeStep - 1));
    navigate(
      `/application-form/${activeStep - 1}?userId=${userId}&id=${financeId}`
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-10"
        >
          <StyledTypography variant="body1" className="mt-20">
            {t("PERSONAL_INFO")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2} className="mb-8">
                  <Controller
                    name="title"
                    control={control}
                    defaultValue={titles[0].id}
                    render={({ field }) => (
                      <CustomAutocomplete
                        label={t("TITLE")}
                        onChange={(value: any) => {
                          if (value && value.id) {
                            field.onChange(value.id);
                          } else {
                            field.onChange(null);
                          }
                        }}
                        value={field.value || ""}
                        options={titles?.map((item) => ({
                          label: t(item.label),
                          id: item.id,
                        }))}
                        required
                        error={errors.title && t(errors.title.message)}
                      />
                    )}
                  />
                </Grid>
                {watch("title") === "OTHER" && (
                  <Grid item xs={12} sm={2}>
                    <Controller
                      name="other"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label={t("OTHER")}
                          fullWidth
                          required
                          value={field.value}
                          onChange={field.onChange}
                          error={errors?.other?.message}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={watch("title") === "OTHER" ? 5 : 7}
                  className="mb-8"
                >
                  <Controller
                    name="fullName"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("FULL_NAME")}
                        onChange={field.onChange}
                        fullWidth
                        value={field.value || ""}
                        required
                        helperText={
                          (errors.fullName && t(errors.fullName.message)) ||
                          t("NOTE_ENTER_NAME_AS_PER_IC")
                        }
                        error={errors.fullName && t(errors.fullName.message)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="mb-8">
                  <Controller
                    name="sex"
                    control={control}
                    render={({ field }) => (
                      <CustomAutocomplete
                        label={t("SEX")}
                        required
                        value={field.value || ""}
                        onChange={(value: any) => {
                          if (value && value.id) {
                            field.onChange(value.id);
                          } else {
                            field.onChange(null);
                          }
                        }}
                        options={sexs?.map((item) => ({
                          label: t(item.label),
                          id: item.id,
                        }))}
                        error={errors.sex && t(errors.sex.message)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} className="mb-8">
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    render={({ field }) => (
                      <CustomDatePicker
                        label={t("DOB")}
                        required
                        maxDate={calculateMinimumDOBForAdult()}
                        value={field.value}
                        onChange={field.onChange}
                        error={
                          errors.dateOfBirth && t(errors.dateOfBirth.message)
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Controller
                    name="religion"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        required
                        label={t("RELIGION")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        error={errors.religion && t(errors.religion.message)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="mb-8">
                  <Controller
                    name="race"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("RACE")}
                        required
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        error={errors.race && t(errors.race.message)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} className="mb-8">
                  <Controller
                    name="maritalStatus"
                    control={control}
                    render={({ field }) => (
                      <CustomAutocomplete
                        label={t("MARITAL_STATUS")}
                        onChange={(value: any) => {
                          if (value && value.id) {
                            field.onChange(value.id);
                          } else {
                            field.onChange(null);
                          }
                        }}
                        value={field.value || ""}
                        options={maritalStatusEnum?.map((item) => ({
                          label: t(item.label),
                          id: item.id,
                        }))}
                        required
                        error={
                          errors.maritalStatus &&
                          t(errors.maritalStatus.message)
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <StyledTypography variant="body1" className="mt-20">
            {t("OTHER_DETAIL")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12} className="mb-8">
              <Controller
                name="motherFullName"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label={t("MOTHER_FULL_NAME")}
                    required
                    fullWidth
                    value={field.value || ""}
                    onChange={field.onChange}
                    error={
                      errors.motherFullName && t(errors.motherFullName.message)
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} className="mb-8">
                  <Controller
                    name="jobType"
                    control={control}
                    render={({ field }) => (
                      <CustomAutocomplete
                        label={t("JOB_TYPE")}
                        required
                        value={field.value || ""}
                        options={jobTypes?.map((item) => ({
                          label: t(item.label),
                          id: item.id,
                        }))}
                        onChange={(value: any) => {
                          if (value && value.id) {
                            field.onChange(value.id);
                          } else {
                            field.onChange(null);
                          }
                        }}
                        error={errors.jobType && t(errors.jobType.message)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="mb-8">
                  <Controller
                    name="jobUniqueCode"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("JOB_UNIQUE_CODE")}
                        fullWidth
                        value={field.value || ""}
                        onChange={field.onChange}
                        required={
                          watch("jobType") === "ARMY" ||
                          watch("jobType") === "POLICE"
                        }
                        error={
                          errors.jobUniqueCode &&
                          t(errors.jobUniqueCode.message)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="nationalId"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("NATIONAL_ID")}
                        onChange={field.onChange}
                        required
                        fullWidth
                        isUpperCase={false}
                        inputProps={{ maxLength: 12 }}
                        value={field.value || ""}
                        error={
                          errors.nationalId && t(errors.nationalId.message)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="mb-8">
                  <Controller
                    name="otherId"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("OTHER_ID")}
                        onChange={field.onChange}
                        fullWidth
                        isUpperCase={false}
                        value={field.value || ""}
                        error={errors.otherId && t(errors.otherId.message)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <StyledTypography variant="body1" className="mt-20">
            {t("CONTACT_DETAILS")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="mb-8">
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        type="number"
                        label={t("MOBILE_PHONE")}
                        fullWidth
                        disabled
                        required
                        InputProps={commonPhoneNumberInputProps}
                        value={field.value || ""}
                        onChange={(value: any) => {
                          field.onChange(removeLeadingZero(value));
                        }}
                        error={
                          errors.phoneNumber && errors.phoneNumber.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="mb-8">
                  <Controller
                    name="hpNumber"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        type="number"
                        label={t("HOME_PHONE")}
                        onChange={(value: any) => {
                          field.onChange(removeLeadingZero(value));
                        }}
                        fullWidth
                        InputProps={commonPhoneNumberInputProps}
                        value={field.value || ""}
                        error={errors.hpNumber && errors.hpNumber.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="mb-8">
                  <Controller
                    name="personalEmailAddress"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("PERSONAL_EMAIL")}
                        fullWidth
                        required
                        value={field.value || ""}
                        onChange={field.onChange}
                        error={
                          errors.personalEmailAddress &&
                          errors.personalEmailAddress.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="mb-8">
                  <Controller
                    name="workEmailAddress"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("WORK_EMAIL")}
                        onChange={field.onChange}
                        fullWidth
                        value={field.value || ""}
                        error={
                          errors.workEmailAddress &&
                          errors.workEmailAddress.message
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <StyledTypography variant="body1" className="mt-20">
            {t("QUALIFICATION")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6} className="mb-8">
                  <Controller
                    name="highestQualification"
                    control={control}
                    render={({ field }) => (
                      <CustomAutocomplete
                        label={t("HIGHEST_QUALIFICATION")}
                        required
                        fullWidth
                        options={qualification?.map((item) => ({
                          label: t(item.label),
                          id: item.id,
                        }))}
                        value={field.value || ""}
                        onChange={(value: any) => {
                          if (value && value.id) {
                            field.onChange(value.id);
                          } else {
                            field.onChange(null);
                          }
                        }}
                        error={
                          errors.highestQualification &&
                          t(errors.highestQualification.message)
                        }
                      />
                    )}
                  />
                </Grid>
                {watch("highestQualification") === "Other" && (
                  <Grid item xs={6} className="mb-8">
                    <Controller
                      name="otherValueOfHighestQualification"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label={t("OTHER")}
                          required
                          fullWidth
                          value={field.value || ""}
                          onChange={field.onChange}
                          error={
                            errors.otherValueOfHighestQualification &&
                            t(errors.otherValueOfHighestQualification.message)
                          }
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Box>
            <Box className="flex justify-end items-end gap-20 mt-10">
              {isEdit ? (
                <ColoredSubmitButton
                  className="w-120"
                  disabled={!isValid}
                  text={t("SAVE")}
                  isLoading={isLoading}
                ></ColoredSubmitButton>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    className="capitalize w-120"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {t("PREVIOUS")}
                  </Button>
                  <ColoredSubmitButton
                    className="w-120"
                    disabled={!isValid}
                    text={t("NEXT")}
                    isLoading={isLoading}
                  ></ColoredSubmitButton>
                </>
              )}
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}
