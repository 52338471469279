import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  finalSubmitApplication,
  getIsVerificationDialogOpen,
  getIsFinanceProcessDialogOpen,
  selectIsButtonLoading,
  sendOTP,
  setFinanceProcessDialogOpen,
  setVerificationDialogOpen,
  getIsOTPGenerateLoading,
} from "app/store/application-form/applicationFormSlice";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import OtpVerification from "app/shared-components/OtpVerification/OtpVerification";
import { useTranslation } from "react-i18next";
import themesConfig from "app/configs/themesConfig";
import { generateAndVerifyOTPType, videoUrl } from "src/app/common/constant";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import Box from "@mui/material/Box";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import { Button } from "@mui/material";
import { StyledTypographyGrey } from "../Styles/CustomStyle";

i18next.addResourceBundle("en", "FinalApplicationSubmit", en);
i18next.addResourceBundle("ms", "FinalApplicationSubmit", ms);

const FinalApplicationSubmit = ({ applicationId, phoneNumber }) => {
  const { t } = useTranslation("FinalApplicationSubmit");
  const dispatch: any = useDispatch();
  const isLoading = useSelector(selectIsButtonLoading);
  const verificationDialogOpen = useSelector(getIsVerificationDialogOpen);
  const isGenerateOTPLoading = useSelector(getIsOTPGenerateLoading);
  const financeProcessDialogOpen = useSelector(getIsFinanceProcessDialogOpen);
  const videoRef = useRef(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoEnded, setVideoEnded] = useState(false);

  useEffect(() => {
    if (financeProcessDialogOpen && videoRef.current) {
      videoRef.current.load();
    }
  }, [financeProcessDialogOpen]);

  const handleFinalSubmit = (data) => {
    const request_data = {
      financeApplicationId: applicationId,
      otp: data.otp,
    };
    dispatch(finalSubmitApplication(request_data));
  };

  const handleVerificationDialogClose = () => {
    dispatch(setVerificationDialogOpen(false));
  };

  const handleFinanceProcessDialogClose = () => {
    dispatch(setFinanceProcessDialogOpen(false));
  };

  const handleVideoLoadedMetadata = () => {
    if (videoRef.current) {
      setVideoDuration(videoRef.current.duration);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleVideoEnded = () => {
    setVideoEnded(true);
  };

  const handleSendOtp = () => {
    dispatch(
      sendOTP({
        mobile: phoneNumber,
        otpType: generateAndVerifyOTPType?.SubmitFinanceApplication,
      })
    );
  };

  const verificationComponent = (
    <OtpVerification
      color={themesConfig.default.palette.common.black}
      onOtpSubmit={handleFinalSubmit}
      loading={isLoading}
      onResendOtp={handleSendOtp}
      isLogin={false}
      phoneNumber={phoneNumber}
    />
  );

  const remainingTime = videoDuration - currentTime;

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const videoComponent = (
    <>
      <Box className="mt-10">
        <video
          ref={videoRef}
          width="100%"
          height="auto"
          controls
          controlsList="nodownload" // download button removed
          autoPlay
          onLoadedMetadata={handleVideoLoadedMetadata}
          onTimeUpdate={handleTimeUpdate}
          onEnded={handleVideoEnded}
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
      <StyledTypographyGrey
        variant="body1"
        className="mt-8 italic !font-normal"
      >
        {t("VIDEO_ENDED_MESSAGE")} {!videoEnded && formatTime(remainingTime)}
      </StyledTypographyGrey>
      <div className="flex justify-end gap-10 my-20">
        <Button
          variant="outlined"
          onClick={handleFinanceProcessDialogClose}
          className="capitalize"
        >
          {t("DISAGREE")}
        </Button>
        <ColoredSubmitButton
          onClick={handleSendOtp}
          text={`${t("AGREE")} ${!videoEnded ? formatTime(remainingTime) : ""}`}
          className="px-20"
          isLoading={isGenerateOTPLoading}
          disabled={currentTime < videoDuration}
        />
      </div>
    </>
  );

  return (
    <>
      <CustomDialog
        open={verificationDialogOpen}
        onClose={handleVerificationDialogClose}
        content={{
          title: t("VERIFY_OTP"),
          component: verificationComponent,
        }}
        maxWidth="xs"
      />
      <CustomDialog
        open={financeProcessDialogOpen}
        onClose={handleFinanceProcessDialogClose}
        content={{
          title: "",
          component: videoComponent,
        }}
        maxWidth="sm"
      />
    </>
  );
};

export default FinalApplicationSubmit;
