import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Card,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { formatDistanceToNow } from "date-fns";
import notificationSlice, {
  deleteNotification,
  getAllNotificationDetail,
  getIsDeleteAllDialog,
  getIsDeleteOneCardDialog,
  getNotification,
  getNotificationListLoading,
  getNotificationListPaginationData,
  markAsRead,
  resetNotification,
  setIsDeleteAllDialogOpen,
  setIsDeleteOneCardDialogOpen,
  setNotificationListPagination,
} from "./store/notificationSlice";
import withReducer from "app/store/withReducer";
import ConfirmDialog from "app/shared-components/Dialog/confirmDialog";
import { makeStyles } from "@mui/styles";
import themesConfig from "app/configs/themesConfig";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import Can from "src/app/services/FeaturePermission/Can";
import { hasPermission, notificationRedirect } from "src/app/services/utils";
import ErrorMessage from "src/app/services/FeaturePermission/ErrorMessage";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import moment from "moment";
import { isEmpty, throttle } from "lodash";

const useStyles = makeStyles(() => ({
  bgGrey: {
    background: themesConfig.greyDark.palette.warning.contrastText,
  },
  bgWhite: {
    background: themesConfig.default.palette.common.white,
  },
}));

function NotificationPage() {
  const classes = useStyles();
  const { t } = useTranslation("Notification");
  const scrollPage = useRef<HTMLDivElement | null>(null);
  const notificationListPagination = useSelector(
    getNotificationListPaginationData
  );
  const dispatch: any = useDispatch();
  const isNotificationLoading = useSelector(getNotificationListLoading);
  const isPermissionDeleteNotification = hasPermission(
    FeaturePermissions.UserNotification.Delete
  );
  const isPermissionToReadNotification = hasPermission(
    FeaturePermissions.UserNotification.MarkAsRead
  );

  if (!hasPermission(FeaturePermissions.UserNotification.List)) {
    return <ErrorMessage errorMessage="NOT_ALLOWED_NOTIFICATION" />;
  }

  const notificationDetail = useSelector(getAllNotificationDetail);

  useEffect(() => {
    if (isEmpty(notificationDetail)) {
      dispatch(getNotification());
    }
  }, [dispatch]);

  const isDeleteAllDialogOpen = useSelector(getIsDeleteAllDialog);

  const isDeleteOneCard = useSelector(getIsDeleteOneCardDialog);

  const [notificationId, setNotificationId] = useState("");

  const handleDeleteOpen = () => {
    dispatch(setIsDeleteAllDialogOpen(true));
  };
  const handleDeleteClose = () => {
    dispatch(setIsDeleteAllDialogOpen(false));
  };
  const confirmDeleteDialog = () => {
    setIsDeleteAllDialogOpen(false);
    dispatch(deleteNotification());
  };
  const handleDeleteOnecard = (id) => {
    setNotificationId(id);
    dispatch(setIsDeleteOneCardDialogOpen(true));
  };
  const handleDeleteOneCardClose = () => {
    dispatch(setIsDeleteOneCardDialogOpen(false));
  };
  const confirmDeleteOneCard = () => {
    dispatch(setIsDeleteOneCardDialogOpen(false));
    dispatch(deleteNotification(notificationId));
  };
  const handleMarkAsRead = (notification, isRead) => {
    if (!isRead && isPermissionToReadNotification) {
      dispatch(markAsRead(notification.id));
    }
    notificationRedirect(notification);
  };

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (!scrollPage.current) return;

      const { scrollTop, scrollHeight, clientHeight } = scrollPage.current;

      if (scrollHeight - scrollTop <= clientHeight + 100) {
        if (notificationListPagination.totalCount > notificationDetail.length) {
          dispatch(
            setNotificationListPagination({
              pageIndex: notificationListPagination.pageIndex + 1,
              pageSize: notificationListPagination.pageSize,
            })
          );
          dispatch(getNotification());
        }
      }
    }, 1000); // 1000ms throttle (1 second)

    const pageNode = scrollPage.current;
    if (pageNode) {
      pageNode.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (pageNode) {
        pageNode.removeEventListener("scroll", handleScroll);
      }
    };
  }, [dispatch, notificationDetail, notificationListPagination]);

  return (
    <Can
      permission={FeaturePermissions.UserNotification.List}
      errorMessage="NOT_ALLOWED_NOTIFICATION"
    >
      <div ref={scrollPage} className="max-h-[80vh] overflow-y-auto">
        <div className="flex w-full container">
          <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 p-24 md:p-32 pb-0 md:pb-0">
            <div className="flex flex-col flex-auto">
              <Typography className="text-3xl font-semibold tracking-tight leading-8">
                {t("NOTIFICATIONS")}
              </Typography>
              <Typography
                className="font-medium tracking-tight"
                color="text.secondary"
              >
                {t("LISTS_ALL_NOTIFICATIONS")}
              </Typography>
            </div>
            {isPermissionDeleteNotification &&
              notificationDetail &&
              notificationDetail.length > 0 && (
                <div className="flex items-center mt-24 sm:mt-0 sm:mx-8 space-x-12">
                  <Button
                    className="whitespace-nowrap capitalize"
                    variant="contained"
                    color="secondary"
                    onClick={handleDeleteOpen}
                    startIcon={
                      <FuseSvgIcon size={20}>heroicons-solid:bell</FuseSvgIcon>
                    }
                  >
                    {t("DELETE_ALL")}
                  </Button>
                </div>
              )}
          </div>
        </div>
        <div className="p-24 mt-24 container">
          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 p-8 gap-10">
            {!isEmpty(notificationDetail) &&
              notificationDetail?.map((notification, index) => (
                <Card
                  className={`relative flex min-h-64 w-full items-center space-x-8 rounded-16 p-20 shadow ${
                    notification.isRead === true
                      ? `${classes.bgWhite}`
                      : `${classes.bgGrey}`
                  }`}
                  elevation={0}
                  component="div"
                  role="button"
                  key={index}
                  onClick={() =>
                    handleMarkAsRead(notification, notification.isRead)
                  }
                >
                  <Box
                    sx={{ backgroundColor: "background.default" }}
                    className="mr-12 flex h-32 w-32 shrink-0 items-center justify-center rounded-full"
                  >
                    {notification.isRead === false ? (
                      <Badge
                        className="text-xl"
                        color="secondary"
                        variant="dot"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                      >
                        <FuseSvgIcon className="opacity-75" color="inherit">
                          heroicons-solid:star
                        </FuseSvgIcon>
                      </Badge>
                    ) : (
                      <FuseSvgIcon className="opacity-75" color="inherit">
                        heroicons-solid:star
                      </FuseSvgIcon>
                    )}
                  </Box>
                  <div className="flex flex-auto flex-col">
                    {notification.title && (
                      <Typography className="line-clamp-1 font-semibold">
                        {notification.title}
                      </Typography>
                    )}

                    {notification.description && (
                      <div
                        className="text-justify mr-5"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: notification.description,
                        }}
                      />
                    )}

                    {notification.created && (
                      <Typography
                        className="mt-8 text-sm leading-none "
                        color="text.secondary"
                      >
                        {formatDistanceToNow(
                          moment(
                            notification.created,
                            "DD/MMM/YYYY HH:mm:ss"
                          ).toDate(),
                          { addSuffix: true }
                        )}
                      </Typography>
                    )}
                  </div>
                  {isPermissionDeleteNotification && (
                    <IconButton
                      disableRipple
                      className="absolute right-0 top-0 p-8"
                      color="inherit"
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteOnecard(notification.id);
                      }}
                    >
                      <FuseSvgIcon
                        size={18}
                        className="opacity-75"
                        color="inherit"
                      >
                        heroicons-solid:x
                      </FuseSvgIcon>
                    </IconButton>
                  )}
                </Card>
              ))}
          </div>
          {isNotificationLoading && (
            <div className="flex justify-center">
              <CircularProgress
                size={24}
                color="secondary"
                className="button-progress"
              />
            </div>
          )}
          {notificationDetail?.length === 0 && !isNotificationLoading && (
            <div className="flex flex-col flex-1 items-center justify-center p-16 gap-10">
              <NotificationsOffIcon color="disabled" className="text-10xl" />
              <Typography
                className="text-center text-24"
                color="text.secondary"
              >
                {t("THERE_ARE_NO_NOTIFICATION_FOR_NOW")}
              </Typography>
            </div>
          )}
        </div>

        {isDeleteAllDialogOpen && (
          <ConfirmDialog
            open={isDeleteAllDialogOpen}
            title={t("ARE_YOU_SURE_TO_DELETE_ALL_NOTIFICATIONS")}
            handleConfirm={confirmDeleteDialog}
            handleClose={handleDeleteClose}
            cancelText={t("CANCEL")}
            okText={t("OK")}
          />
        )}
        {isDeleteOneCard && (
          <ConfirmDialog
            open={isDeleteOneCard}
            title={t("ARE_YOU_SURE_TO_DELETE")}
            handleConfirm={confirmDeleteOneCard}
            handleClose={handleDeleteOneCardClose}
            cancelText={t("CANCEL")}
            okText={t("OK")}
          />
        )}
      </div>
      {notificationDetail?.length > 0 && (
        <Typography
          component="div"
          className="container italic p-24 text-left text-grey-700"
        >
          {t(
            "NOTIFICATION_WITH_WHITE_BACKGROUND_AND_BADGES_INDICATE_UNREAD_NOTIFICATION"
          )}
        </Typography>
      )}
    </Can>
  );
}
export default withReducer(
  "notification",
  notificationSlice.reducer
)(NotificationPage);
// export default NotificationPage;
