import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { getSlug, selectUser } from "app/store/user/userSlice";
import { pages } from "src/app/common/pageConfig";
import { useTranslation } from "react-i18next";
import {
  getActiveFinanceDetail,
  getIsActiveFinanceApplicationDialogOpen,
  setActiveFinanceDetail,
  setIsActiveFinanceApplicationDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import PendingKYCDialog from "app/shared-components/Dialog/PendingKYCDialog";
import { isEmpty } from "lodash";
import { pageFilter } from "src/app/services/utils";

/**
 * The user menu.
 */
function UserMenu() {
  const { t } = useTranslation("user");
  const isActiveFinanceApplicationDialogOpen = useSelector(
    getIsActiveFinanceApplicationDialogOpen
  );
  const activeFinanceDetail = useSelector(getActiveFinanceDetail);
  const dispatch: any = useDispatch();
  const user = useSelector(selectUser);
  const slug = useSelector(getSlug);

  const [userMenu, setUserMenu] = useState<HTMLElement | null>(null);

  const userMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const filteredPages = pageFilter(pages, slug);

  return (
    <>
      {!user.role || user.role.length === 0 ? (
        <Button
          variant="outlined"
          component={Link}
          to="/sign-in"
          className="capitalize"
        >
          {t("LOG_IN")}
        </Button>
      ) : (
        <Button
          className="min-h-40 min-w-40 p-0 md:px-16 md:py-6"
          onClick={userMenuClick}
          color="inherit"
        >
          <div className="mx-4 flex-col items-end flex">
            <Typography
              className="text-11 font-medium capitalize"
              color="text.secondary"
            >
              {t("WELCOME")}
            </Typography>
            <Typography
              component="span"
              className="flex font-semibold capitalize"
            >
              {user.data.displayName}
            </Typography>
          </div>
        </Button>
      )}

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        <>
          {filteredPages &&
            filteredPages?.map((page) => (
              <MenuItem
                component={NavLink}
                to={`/user/${page.url}`}
                onClick={userMenuClose}
                key={page.id}
                role="button"
              >
                <ListItemIcon className="min-w-40">
                  <page.icon />
                </ListItemIcon>
                <ListItemText primary={t(page.title)} />
              </MenuItem>
            ))}
          <MenuItem
            component={NavLink}
            to="/sign-out"
            onClick={() => {
              userMenuClose();
            }}
          >
            <ListItemIcon className="min-w-40">
              <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
            </ListItemIcon>
            <ListItemText primary={t("SIGN_OUT")} />
          </MenuItem>
        </>
      </Popover>
      {!isEmpty(activeFinanceDetail) &&
        isActiveFinanceApplicationDialogOpen && (
          <PendingKYCDialog
            open={isActiveFinanceApplicationDialogOpen}
            onClose={() => {
              dispatch(setIsActiveFinanceApplicationDialogOpen(false));
              dispatch(setActiveFinanceDetail(null));
            }}
          />
        )}
    </>
  );
}

export default UserMenu;
