import React, { useEffect, useState } from "react";
import { Button, Box, Typography, Grid, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { acceptFileTypeImageAndPDF } from "src/app/common/constant";
import FilePreview from "../preview-file/FilePreview";
import { useSelector } from "react-redux";
import {
  getSelectedDocumentPath,
  setSelectedDocumentPath,
} from "app/store/user/userSlice";
import themesConfig from "app/configs/themesConfig";

const useStyles = makeStyles({
  previewBox: {
    marginTop: 16,
    width: 250,
    height: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #ddd",
    borderRadius: 4,
  },
  selectButton: {
    borderColor: themesConfig.default.palette.secondary.main,
    color: themesConfig.default.palette.secondary.main,
    width: "100%",
    textTransform: "capitalize",
  },
});

const FileUpload = ({
  onChange,
  onRemove,
  existingFile = null,
  acceptedFileTypes = acceptFileTypeImageAndPDF,
  isImageLoading = false,
}) => {
  const { t } = useTranslation("applicationForm");
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const filePath = useSelector(getSelectedDocumentPath);

  useEffect(() => {
    if (existingFile) {
      setFilePreview(existingFile);
      setIsFileRemoved(false);
    } else {
      setFilePreview(null);
    }
  }, [filePath, existingFile]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    onChange(file);
    setIsFileRemoved(false);
  };

  const handleClearFile = () => {
    setFilePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setIsFileRemoved(true);
    dispatch(setSelectedDocumentPath(null));
    onRemove();
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Box display="flex" flexDirection="column" alignItems="start">
          <Button
            variant="outlined"
            component="label"
            className={classes.selectButton}
          >
            {t("SELECT_FILE")}
            <input
              type="file"
              hidden
              accept={acceptedFileTypes}
              onChange={handleFileChange}
            />
          </Button>
          <Typography
            variant="body2"
            color="textSecondary"
            className="italic mt-10 w-192"
          >
            {t("SUPPORT_FILE")}: {acceptedFileTypes}
          </Typography>
        </Box>
      </Grid>
      {isImageLoading ? (
        <CircularProgress color="secondary" size={24} />
      ) : (
        !isFileRemoved &&
        filePreview && (
          <Grid item>
            <Box className={classes.previewBox}>
              {filePreview && <FilePreview fileUrl={filePreview} />}
            </Box>
            <a onClick={handleClearFile} className="cursor-pointer">
              {t("REMOVE")}
            </a>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default FileUpload;
