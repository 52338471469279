import React, { useState, forwardRef } from 'react';
import { Autocomplete, CircularProgress, Link, TextField, TextFieldVariants } from '@mui/material';
import { find } from 'lodash';

type CommonAutocompleteProps = {
  label: string;
  variant?: TextFieldVariants;
  onChange: (newValue: string) => void;
  options: any[];
  error?: string ; 
  required?:boolean;
  loading? : boolean;
};
type AdditionalProps = {
  [key: string]: any;
};

type CustomAutocompleteProps = CommonAutocompleteProps & AdditionalProps;
const CustomAutocomplete: React.FC<CustomAutocompleteProps> = forwardRef(({ label, variant = "filled", onChange, options = [], error='',required=false, value,loading = false, ...props }, ref) => {
  options = options || [];
  let selectedValues = value;
  if (options.length > 0 && typeof options[0] === 'object'){
    selectedValues = find(options, (option: any) => option.id == value);
  }

  const handleOnChange = (event, selectedOption) => {
    if (selectedOption) {
      selectedOption = typeof selectedOption === 'object' 
        ? { ...selectedOption, label: selectedOption.label.toUpperCase() } 
        : selectedOption.toUpperCase();
    }
    onChange(selectedOption);
  };
  
   // Custom renderOption to show a blue link-like button for "Add" option
   const renderCustomOption = (props, option) => {
    if (option?.id === "add") {
      return (
        <li {...props} key={option.id}>
          <Link
            component="button"
            variant="body2"
            color="secondary"
            underline="none"
            onClick={() => {
              onChange(option);
            }}
          >
            +  {option?.label}
          </Link>
        </li>
      );
    }
    return (
      <li {...props} key={option.id || option.label || option}>
        {option.label || option}
      </li>
    );
  };

  return (
    <Autocomplete
    {...props}
      onChange={handleOnChange}
      options={options}
      getOptionLabel={(option:any) => option?.label?.toUpperCase() || option?.toUpperCase()}
      renderOption={renderCustomOption}
      ref={ref} 
      defaultValue={selectedValues || null}
      value={selectedValues || null}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required= {required}
          variant={variant}
          error={!!error} 
          helperText={error} 
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="secondary" size={15}  /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});

export default CustomAutocomplete;