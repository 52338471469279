const locale = {
  LOG_IN: "Log Masuk",
  SIGN_OUT: "Log keluar",
  WELCOME: "Selamat datang,",
  DASHBOARD: "Papan pemuka",
  PERSONAL_INFO: "Maklumat Peribadi",
  ADDITIONAL_INFORMATION: "Maklumat Tambahan",
  CONTACT: "Hubungi",
  ADDRESS_INFO: "Maklumat Alamat",
  MY_APPLICATION: "Permohonan Saya",
  MEMBER: "Ahli",
  APPLY_FOR_FINANCE: "Mohon Pembiayaan",
  REGISTER: "Daftar Ahli",
  DOCUMENTS: "Dokumen",
  SEARCH_FIELD_LABEL: "Cari Permohonan Anda",
  UPLOAD_DOCUMENT: "Muat Naik Dokumen",
  CANCEL: "Batal",
  DOCUMENT_TYPE: "Jenis Dokumen",
  ERROR_DOCUMENT: "Dokumen diperlukan",
  ERROR_DOCUMENT_TYPE: "Pilih Jenis Dokumen",
  ADDRESS: "Alamat",
  DETAILS_0F_SPOUSE: "Butiran Pasangan",
  DETAILS_0F_REFERRAL: "Rujukan Keluarga (Tidak tinggal bersama)",
  DETAILS_0F_NEXT_OF_KIN: "Butiran waris",
  CONTACT_DETAILS: "Butiran Hubungan",
  APPLICATION_ID: "ID Permohonan",
  FINANCE_PRODUCT: "Produk Kewangan",
  CATEGORY_NAME: "Nama Kategori",
  CREATED_DATE: "Tarikh Dicipta",
  AMOUNT: "Jumlah",
  TITLE: "Gelaran",
  NAME: "Nama",
  OTHER_DETAILS: "Butiran Lain",
  MOTHER_FULL_NAME: "Nama Penuh Ibu",
  JOB_TYPE: "Butiran Pekerjaan",
  JOB_UNIQUE_CODE: "Jawatan",
  MEMBERSHIP_NUMBER: "Nombor Keahlian",
  OTHER_ID: "No ID Lain",
  DATE_OF_BIRTH: "Tarikh Lahir",
  SEX: "Jantina",
  STATUS: "Status",
  NATIONAL_ID: "No IC Baru",
  NATIONAL_ID_OR_PASSPORT: "No IC / Pasport Baru",
  RACE: "Bangsa",
  RELIGION: "Agama",
  QUALIFICATION: "Kelayakan",
  HIGHEST_QUALIFICATION: "Kelayakan Tertinggi",
  HOUSE_ADDRESS: "Alamat Rumah",
  POST_CODE: "Poskod",
  CITY: "Bandar",
  STATE: "Negeri",
  HOUSE_STATUS: "Status Rumah",
  MOBILE_NUMBER: "Nombor Telefon Bimbit",
  DEFAULT_LANGUAGE: "Bahasa Asas",
  HOME_PHONE_NUMBER: "Nombor Telefon Rumah",
  PERSONAL_EMAIL: "Emel Peribadi",
  WORK_EMAIL: "Emel Kerja",
  MAILING_ADDRESS: "Alamat Surat Menyurat",
  MAILING_CITY: "Bandar Surat Menyurat",
  MAILING_STATE: "Negeri Surat Menyurat",
  MAILING_CONTACT_DETAILS: "Butiran Hubungan Surat Menyurat",
  SIBLING_NAME: "Nama Adik Beradik",
  RESIDENTIAL_LEVEL: "Jenis Pemilikan Kediaman",
  OWN: "Sendiri",
  RENT: "Sewa",
  PARENTS: "Ibu Bapa",
  FATHER_OWN: "Milik Bapa",
  RELATIONSHIP: "Hubungan",
  BROTHER: "Abang",
  OTHER: "Lain-lain",
  EMAIL: "Emel",
  HP_NO: "Nombor Telefon Bimbit",
  HOUSE_NUMBER: "Nombor Rumah",
  HOUSE_CITY: "Bandar Rumah",
  HOUSE_STATE: "Negeri Rumah",
  SAME_ADDRESS: "Adakah alamat surat menyurat sama dengan alamat rumah?",
  EMPLOYMENT_DETAILS: "Butiran Pekerjaan",
  EMPLOYER_NAME: "Nama Majikan",
  DEPARTMENT: "Jabatan",
  JOB: "Pekerjaan",
  GRADE: "Gred",
  EMPLOYMENT_STATUS: "Status Pekerjaan",
  EMPLOYMENT_DATE: "Tarikh Mula Bekerja:",
  RETIREMENT_AGE: "opsyen persaraan",
  START_DATE_OF_WORK: "Tarikh Mula Bekerja",
  END_DATE_OF_WORK: "Tarikh Tamat Bekerja",
  PERIOD_OF_SERVICE: "Tempoh Perkhidmatan",
  OFFICE_ADDRESS: "Alamat Pejabat",
  OFFICE_CITY: "Bandar Pejabat",
  OFFICE_STATE: "Negeri Pejabat",
  OFFICE_PHONE: "Nombor Telefon Pejabat",
  INCOME_DETAILS: "Butiran Pendapatan",
  SALARY_DETAIL: "Butiran Gaji",
  BASIC_SALARY: "Gaji Asas",
  FIXED_ALLOWANCES: "Elaun Tetap",
  TOTAL_DEDUCTION: "Jumlah Potongan",
  SALARY_INCREMENT: " Kenaikan Gaji",
  NET_SALARY: "Gaji Bersih",
  DATE_OF_INCREMENT: "Tarikh kenaikan Gaji",
  REMARKS: "Ulasan",
  BANK_DETAILS: "Butiran Bank",
  NAME_OF_BANK: "Nama Bank",
  ACCOUNT_NUMBER: "Nombor Akaun",
  BANK_BRANCH: "Cawangan Bank",
  TYPE_OF_ACCOUNT: "Jenis Akaun",
  FINANCE_DETAILS: "Butiran Pembiayaan",
  AMOUNT_OF_FINANCING: "Jumlah Pembiayaan",
  TENURE_OF_APPLICATION: "Tempoh Permohonan",
  INTEREST_RATE: "Kadar Faedah (%)",
  APPLICATION_NUMBER: "Nombor Permohonan",
  MEMBERSHIP_FEES: "Yuran Keahlian",
  REGISTRATION_FEES: "Yuran Pendaftaran",
  PRODUCT_DETAILS: "Butiran Produk",
  PRODUCT_NAME: "Nama Produk",
  PRODUCT_VARIANT_NAME: "Varian Produk",
  CATEGORY: "Kategori",
  DURATION_MONTH: "Tempoh Bulan",
  LOAN_RATE_TYPE: "Jenis Kadar Pinjaman",
  DESCRIPTION: "Keterangan",
  ROW_NUMBER: "Bilangan Baris",
  FINANCE_NAME: "Nama Institusi Kewangan",
  LOAN_BALANCE: "Imbangan Kewangan",
  MONTHLY_COMMITMENTS: "Bayaran Balik Bulanan",
  LOAN_TENURE: "Tempoh Pembiayaan Baki (Bulan)",
  SETTLEMENT_LETTER: "Adakah anda mempunyai Surat Penyelesaian?",
  VALIDITY_PERIOD: "Tempoh Sah",
  FINANCE_FILE: "Muat naik fail",
  SUBMITTED: "Dihantar",
  UNDER_REVIEW: "Dalam Ulasan",
  APPROVED: "Disahkan",
  PROCESSING: "Dalam Pemprosesan",
  DISBURSED: "Dibayar",
  CLOSED: "Ditutup",
  ADD_DOCUMENT: "+ Tambah Dokumen",
  COMMENTS: " Komen",
  SUBMIT: "Hantar",
  UPLOADED_BY: "Dimuat Naik Oleh:",
  UPLOADED_ON: "Dimuat Naik Pada:",
  CREATED_AT: "Dicipta Pada",
  ACTIONS: "Tindakan",
  EDIT: "Edit",
  EDIT_PERSONAL_INFO: "Edit Maklumat Peribadi",
  EDIT_ADDITIONAL_INFO: "Edit Maklumat Tambahan",
  EDIT_ADDRESS: "Edit Alamat",
  DELETE: "Padam",
  CONFORM_DELETE: "Adakah anda pasti ingin memadam dokumen ini?",
  DELETE_DOCUMENT: "Padam Dokumen",
  CHOOSE_FILE: "Pilih Fail",
  ADD_MEMBER: "Tambah Ahli",
  SAVE: "Simpan",
  SAVE_AND_CONTINUE: "Simpan & Teruskan",
  FINISH: "Selesai",
  CLOSE: "tutup",
  NO_FINANCE: "Tiada pembiayaan sedia ada",
  USER_ADDITIONAL_SECTION_REMARK: "Maklumat Tambahan Pengguna Catatan",
  USER_ADDRESS_SECTION_REMARK: "Ulasan butiran alamat",
  USER_RELATIVE_SECTION_REMARK: "Ulasan butiran keluarga",
  USER_EMPLOYMENT_SECTION_REMARK: "Ulasan butiran pekerjaan",
  USER_INCOME_SECTION_REMARK: "Ulasan butiran pendapatan",
  USER_FINANCE_SECTION_REMARK: "Ulasan butiran kewangan pengguna",
  USER_BANK_SECTION_REMARK: "Ulasan butiran bank",
  USER_DOCUMENT_SECTION_REMARK: "Ulasan butiran dokumen pengguna",
  NO_REMARK: "Tiada ulasan dijumpai untuk bahagian ini.",
  PASSWORD: "Kata laluan",
  CONFIRM_PASSWORD: "Sahkan Kata Laluan",
  NO_COMMINTS: "Tiada Ulasan Ditemui",
  MINI_AGENT: "Pengurus Wilayah",
  ADD_MINI_AGENT: "Tambah Pengurus Wilayah",
  CONFIRM_SUBMIT_APPLICATION_TITLE: "Sahkan Penyerahan",
  CONFIRM_SUBMIT_APPLICATION_MESSAGE:
    "Adakah anda pasti mahu menyerahkan permohonan anda?",
  FINAL_SUBMIT_TOOLTIP:
    "Sila isi semua butiran yang diperlukan sebelum menghantar permohonan.",
  CHANGE_DETECT_TITLE: "Adakah anda pasti mahu membuang perubahan?",
  CHANGE_DETECT_BUTTON: "Teruskan Mengedit",
  ARMY: "Tentera",
  POLICE: "Polis",
  MALE: "jantan",
  FEMALE: "perempuan",
  MR: "Encik.",
  MRS: "Puan.",
  MISS: "Cik.",
  NO_DOCUMENT: " Tiada dokumen ditemui.",
  DOWNLOAD: "Muat turun",
  DETAILS: "Butiran",
  COMMUNICATION: "Komunikasi",
  MEMBER_DETAILS: "Butiran Ahli",
  MINI_AGENT_DETAIL: "Butiran Pengurus Wilayah",
  PRIMARY: "Rendah",
  SECONDARY: "Menengah",
  DIPLOMA: "Diploma",
  DEGREE: "Ijazah Sarjana Muda",
  MASTERS: "Ijazah Sarjana",
  DOCTORATE_DEGREE: "Ijazah Kedoktoran",
  PROFESSOR: "Kelulusan Prof",
  DOCUMENT_SIGNED: "Tandatangan Dokumen",
  AGENT_CODE: "Kod Agensi/Pengurus",
  DISCARD: "Buang",
  MEMBER_NAME: "Nama Ahli",

  // Mesej Pengesahan
  TITLE_REQUIRED_VALIDATION: "Gelaran diperlukan.",
  FULLNAME_REQUIRED_VALIDATION: "Nama penuh diperlukan.",
  EMAIL_VALIDATION: "Sila masukkan alamat emel yang sah.",
  PHONE_NUMBER_INVALID_VALIDATION: "Nombor telefon diperlukan.",
  PHONE_NUMBER_INVALID: "Sila berikan nombor telefon yang sah.",
  DEFAULT_LANGUAGE_REQUIRED_VALIDATION: "Bahasa asas diperlukan.",
  SEX_REQUIRED_VALIDATION: "Sila Pilih Jantina",
  JOB_TYPE_REQUIRED_VALIDATION: "Sila Pilih Jenis Pekerjaan",
  NATIONAL_ID_REQUIRED_VALIDATION: "Anda mesti masukkan kad pengenalan anda",
  RELIGION_REQUIRED_VALIDATION: "Masukkan agama",
  MOTHER_FULL_NAME_REQUIRED_VALIDATION: "Nama Ibu Diperlukan",
  RACE_REQUIRED_VALIDATION: "Bangsa Diperlukan",
  JOB_UNIQUE_CODE_REQUIRED_VALIDATION: "Masukkan Jawatan",
  MEMBERSHIP_NUMBER_INVALID_VALIDATION: "Masukkan Nombor Keahlian",
  HIGHEST_QUALIFICATION_REQUIRED_VALIDATION: "Berikan Kelayakan Tertinggi Anda",
  HOUSE_ADDRESS_VALIDATION: "Sila masukkan alamat rumah",
  POSTCODE_VALIDATION: "Poskod diperlukan",
  CITY_VALIDATION: "Nama bandar diperlukan",
  STATE_VALIDATION: "Negeri diperlukan",
  MAILING_ADDRESS_VALIDATION: "Alamat surat menyurat diperlukan",
  MAILING_POSTCODE_VALIDATION: "Poskod surat menyurat diperlukan",
  MAILING_CITY_VALIDATION: "Nama bandar surat menyurat diperlukan",
  MAILING_STATE_VALIDATION: "Negeri surat menyurat diperlukan",
  HOUSE_STATUS_REQUIRED_VALIDATION: "Status Rumah diperlukan",
  PASSWORD_VALIDATION_REQUIRED: "Sila masukkan kata laluan anda.",
  PASSWORD_VALIDATION_MIN:
    "Kata laluan terlalu pendek - sekurang-kurangnya 8 aksara.",
  CONFIRM_PASSWORD_VALIDATION_MATCH: "Kata laluan mesti sepadan",
  OTHER_VALUE_VALIDATION: "Sila Masukkan Peringkat Kediaman",
  REQUIRED: "Diperlukan",
  JOB_UNIQUE_CODE_VALIDATION: "Masukkan Jawatan",
  SERIAL_NO: "Tidak.",
  SUPPORT_FILE: "Fail yang disokong",
  DOWNLOAD_PDF: "Muat turun PDF",
  ADD_ROW: "Tambah Baris",
  DELETE_EXISTING_FINANCE_TITLE: "Sahkan",
  DELETE_EXISTING_FINANCE_DESCRIPTION: "Adakah anda pasti untuk memadam?",
  SEND: "Hantar",

  // eKYC DATA
  KYC_COMPLETE: "Pengesahan eKYC Berjaya",
  START_KYC: "Mulakan KYC",
  FONT_BACK_IC: "IC Depan dan Belakang",
  SELFIE: "Selfie Pemilik",
  SELFIE_VALIDATION: "Selfie diperlukan",
  KYC_RESULT: "Keputusan KYC",
  CAMERA_NOT_SUPPORTED:
    "Kamera tidak disokong atau tidak tersedia pada peranti ini.",
  KYC_PROCESS: "Proses KYC",
  KYC_DIS:
    "Sila ikuti langkah-langkah untuk menyelesaikan pengesahan KYC anda.",
  FRONT_IC: "IC Depan",
  FONT_IC_VALIDATION: "IC Depan diperlukan.",
  BACK_IC_VALIDATION: "IC Belakang diperlukan",
  BACK_IC: "IC Belakang",
  CONFORM_SUBMISSION: "Pengesahan Penghantaran",
  CONFIRM_SUBMISSION_DIC:
    "Adakah anda pasti mahu meneruskan dengan gambar yang dihantar?",
  NEXT: "Seterusnya",
  PROCEED: "Teruskan",
  RETAKE: "Ambil Semula",
  USE_PHOTO: "Gunakan Foto",
  CAPTURE: "Tangkap",
  WAIT_MESSAGE: "Sila tunggu, kami sedang memproses KYC anda.",
  WAIT_DIC:
    "Ini mungkin mengambil sedikit masa. Jangan segarkan halaman dan tunggu sementara kami mengesahkan butiran anda.",
  KYC_SUCCESS: "Pengesahan KYC Berjaya!",
  KYC_FAILED: "Pengesahan KYC Gagal.",
  KYC_SUCCESS_DIC:
    "KYC anda telah disahkan. Anda boleh meneruskan untuk menggunakan aplikasi.",
  KYC_FAILED_DIC:
    "Malangnya, pengesahan KYC anda tidak berjaya. Sila semak butiran anda dan cuba lagi.",
  RETRY: "Cuba Lagi",
  VIEW_DETAILS: "Lihat Butiran",
  REJECTED_FINANCE_TOOLTIP:
    "Data permohonan kewangan yang ditolak tidak boleh diedit",
  DOCUMENT_CAPTURE_GUIDELINES: "Garis Panduan Dokumen",
  DOS: "Lakukan",
  DONTS: "Jangan lakukan",
  HOW_IT_WORK: "Bagaimana ia Berfungsi?",
  IDENTITY: "Hanya 3 tangkapan diperlukan untuk mengenal pasti identiti anda.",
  DO_AND_DONT_FOR_FONT_BACK_BEST: [
    {
      do: "Pastikan dokumen anda jelas, tanpa kekaburan, silau, atau bayang-bayang.",
      dont: "Elakkan menutupi mana-mana bahagian dokumen dengan jari atau objek lain.",
    },
    {
      do: "Selaraskan dokumen anda supaya memenuhi keseluruhan bingkai, dengan semua tepi kelihatan.",
      dont: "Elakkan mengambil gambar di kawasan yang kurang terang atau terlalu terang yang mungkin menyembunyikan butiran dokumen anda.",
    },
    {
      do: "Tangkap dokumen anda di kawasan yang terang.",
      dont: "Pastikan dokumen rata dan diselaraskan dengan betul dalam bingkai.",
    },
  ],
  DO_AND_DONT_FOR_IMAGE_BEST: [
    {
      do: "Pastikan keseluruhan wajah anda kelihatan dalam bingkai, tanpa halangan seperti cermin mata hitam, topi, atau topeng.",
      dont: "Elakkan menggunakan sebarang penapis atau alat penyuntingan pada selfie anda. Hantar foto semula jadi.",
    },
    {
      do: "Tunjukkan ekspresi neutral, dengan mata terbuka dan mulut tertutup, seperti gambar pasport.",
      dont: "Elakkan menutupi wajah anda dengan rambut, tangan, atau aksesori seperti selendang yang boleh menghalang identiti anda.",
    },
    {
      do: "Ambil selfie anda di kawasan yang terang dengan latar belakang yang jelas untuk keterlihatan yang jelas.",
      dont: "Jika peranti anda tidak mempunyai kamera, jangan teruskan proses KYC sehingga anda mempunyai akses kepada peranti yang sesuai.",
    },
    {
      do: "Pastikan kamera peranti anda berfungsi dan bersih untuk mengelakkan imej kabur.",
      dont: "Elakkan memuat naik foto yang tidak memenuhi standard kejelasan atau keterlihatan yang diperlukan. Tukar kepada peranti yang dilengkapi kamera.",
    },
  ],
  NO_CAMERA_MESSAGE:
    "Nampaknya peranti anda tidak mempunyai kamera atau kamera tidak dapat diakses. Untuk menyelesaikan proses KYC, anda boleh menyalin pautan di bawah dan mengakses halaman ini dari peranti dengan kamera yang berfungsi.",
  COPY_LINK: "Salin Pautan",
  LINK_COPIED:
    "Pautan telah disalin ke papan klip anda. Anda kini boleh menampalnya ke pelayar di peranti lain untuk meneruskan proses KYC.",
  FONT_BACK_NOTE:
    "Nota: Sila pastikan keseluruhan ID kelihatan, tidak dipangkas dan dalam fokus.",
  COMPLETE_KYC_QR_TITLE: "Lengkapkan Proses KYC Anda",
  COMPLETE_KYC_QR_DES:
    "Imbas kod QR di bawah menggunakan peranti mudah alih anda untuk melengkapkan proses KYC.",
  KYC_LINK: "Pautan KYC",
  TIME_REMAINING: "Masa yang tinggal",
  FRONT_TITLE_MESSAGE: "Selaraskan bahagian depan MyKad anda dengan bingkai.",
  FRONT_PREVIEW_MESSAGE: "Pastikan gambar jelas tanpa silau atau kabur.",
  BACK_TITLE_MESSAGE: "Selaraskan bahagian belakang MyKad anda dengan bingkai.",
  BACK_PREVIEW_MESSAGE: "Pastikan gambar yang diambil jelas tanpa pantulan.",
  SELFIE_TITLE_MESSAGE: "Selaraskan wajah anda di dalam bingkai untuk swafoto.",
  SELFIE_PREVIEW_MESSAGE: "Rakaman bermula dalam {countdownSec}...",
  // eKYC LIVENESS
  GET_READY_TEXT: "Bersedia untuk swafoto video...",
  HOLD_POSITION_TEXT: "Tahan posisi, sedang merakam...",
  CENTER_FACE_TEXT: "Selaraskan wajah anda di dalam bingkai.",
  MOVE_CLOSERtEST: "Anda terlalu jauh, rapatkan diri dengan kamera.",
  MOVE_AWAY_TEXT: "Anda terlalu dekat, bergerak sedikit jauh.",
  CAPTURE_PROCESS_ERROR:
    "Ralat berlaku semasa proses tangkapan. Sila cuba lagi.",
  SDK_NOT_GET_ERROR:
    "Token SDK anda tidak diperoleh untuk KYC, Sila mulakan semula!",
  SDK_ERROR: "Permulaan SDK gagal. Sila mulakan semula proses.",
  WEB_LIB_ERROR: "WAILib tidak dimuatkan. Sila cuba lagi kemudian.",
  REDIRECT_MESSAGE: "Anda akan diubah hala dalam {{seconds}} saat...",

  //Referral Details
  REFERRAL_DETAILS: "Butiran Rujukan",
  REFERRAL_CODE: "Kod Rujukan",
  REFERRAL_LINK: "Pautan Rujukan",
  REFERRAL_INSTRUCTIONS:
    "Imbas Kod QR di bawah untuk mendaftar menggunakan kod ejen",
  COPY_TEXT: "Disalin ke papan keratan.",
  FAIL_COPY: "Gagal menyalin",
  MARITAL_STATUS: "Status Perkahwinan",

  // standart validation messages
  TEXT_FIELD_REQUIRED_VALIDATION: "{{fieldName}} diperlukan",
  SELECT_FIELD_REQUIRED : "Sila pilih pilihan yang sah",
  MINIMUM_VALIDATION: "{{fieldName}} mestilah lebih besar daripada atau sama dengan  {{min}}",
  MAXIMUM_VALIDATION: "{{fieldName}} mestilah kurang daripada atau sama dengan {{max}}",

  FULL_NAME: "Nama Penuh",
  DOCUMENT_NAME: "Nama Dokumen",
  VERIFY_OTP: "Pengesahan",
  REQUIRED_DOCUMENT: "Sila Muat Naik Dokumen yang Diperlukan",
  MANAGER_CODE: "Kod Pengurus",
};

export default locale;
