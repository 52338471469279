import React from "react";
import { styled } from "@mui/system";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
} from "@mui/material";
import themesConfig from "app/configs/themesConfig";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { isFunction } from "lodash"; // Assuming lodash is imported for utility functions

const CustomButton = styled(Button)({
  width: "70%",
  backgroundColor: themesConfig.default.palette.secondary.main,
  color: themesConfig.default.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: themesConfig.default.palette.secondary.dark,
  },
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
}));

const IconContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px",
});

function InfoDialog({
  open,
  onClose,
  title,
  message,
  buttonText,
  onButtonClick,
  icon: Icon,
  color,
  isLoading,
}) {
  const { t } = useTranslation("user");

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="m-10 sm:m-20">
        <DialogTitle className="flex items-center flex-col">
          <IconContainer>
            {Icon && <Icon color={color} style={{ fontSize: "8rem" }} />}
          </IconContainer>
          <StyledTypography fontSize={22} className="font-bold text-center">
            {title}
          </StyledTypography>
        </DialogTitle>
        <DialogContent>
          <StyledTypography
            variant="body1"
            className="text-base sm:text-xl text-justify"
          >
            {message}
          </StyledTypography>
        </DialogContent>
        <DialogActions className="flex flex-col gap-10 pointer-events-auto mb-10">
          <CustomButton onClick={onButtonClick} className="capitalize">
            {isLoading ? (
              <CircularProgress size={24} className="button-progress" />
            ) : (
              buttonText
            )}
          </CustomButton>
          {isFunction(onClose) && onClose !== InfoDialog.defaultProps.onClose && (
            <Typography
              onClick={onClose}
              className="capitalize underline cursor-pointer pointer-events-auto"
            >
              {t("DISCARD")}
            </Typography>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
}

// Setting default props
InfoDialog.defaultProps = {
  open: false,
  onClose: () => {},
  title: "",
  message: "",
  buttonText: "",
  onButtonClick: () => {},
  icon: null,
  color: "inherit",
  isLoading: false,
};

export default InfoDialog;
