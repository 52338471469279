import React, { useEffect, useState } from "react";
import CustomAutoComplete from "../Form/CustomAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import {
  getMemberListData,
  getMemberListForFinance,
  getMemberListLoading,
  setMemberSearchKeyword,
  setMemberListLoading,
  getMemberUserId,
} from "app/store/user/userSlice";
import { useDebounce } from "@fuse/hooks";

function MemberAutoComplete({
  label,
  onChange,
  value,
  disabled,
  error,
  options = {
    showAddOption: false,
    onAddClick: () => {},
  },
}) {
  const dispatch: any = useDispatch();
  const memberList = useSelector(getMemberListData);
  const loading = useSelector(getMemberListLoading);
  const defaultMemberSelect = useSelector(getMemberUserId);
  const [selectedMember, setSelectedMember] = useState(null);

  useEffect(() => {
    setSelectedMember(value);
  }, [value]);

  // Set the default member when available
  useEffect(() => {
    if (!!defaultMemberSelect && !selectedMember) {
      const foundMember = memberList.find(
        (member) => member.id === defaultMemberSelect
      );

      if (foundMember) {
        // Only pass label (name) and id to handleChange
        handleChange({ id: foundMember.id, label: foundMember.fullName });
      }
    }
  }, [defaultMemberSelect, memberList]);

  const getMember = useDebounce(() => {
    if (!selectedMember && !defaultMemberSelect) {
      dispatch(getMemberListForFinance());
    }
  }, 1000);

  const handleChange = (selectedValue) => {

    if (selectedValue?.id === "add") {
      options?.onAddClick?.();
      setSelectedMember(null);
    } else {
      onChange(selectedValue);
      setSelectedMember(selectedValue ? selectedValue.id : null);
      dispatch(setMemberListLoading(false));
    }
  };

  const handleInputChange = (event, newInputValue) => {
    // Check if event exists before accessing its methods
    if (event && newInputValue === "ADD") {
      event.preventDefault();
      return;
    }

    // If there's a default member selected, don't trigger further actions
    if (!!defaultMemberSelect) {
      return;
    }

    // Reset the selected member state if the input is cleared or changed
    setSelectedMember(null);

    // Dispatch the search action if input value is not empty
    dispatch(setMemberSearchKeyword(newInputValue));
    dispatch(setMemberListLoading(true));

    // Trigger the debounced member list fetch
    getMember();
  };

  // Conditionally include the "Add Member" option based on the options.showAddMemberOption prop
  const extendedOptions = [
    ...(options.showAddOption
      ? [
          {
            label: "ADD", // Label for the add option
            id: "add",
          },
        ]
      : []), // Only add this entry if showAddOption is true
    ...(memberList?.map((member) => ({
      label: member?.userAdditionalInformation?.nationalId
        ? `${member.fullName} (${member?.userAdditionalInformation?.nationalId})`
        : member.fullName,
      id: member.id,
    })) || []),
  ];

  return (
    <CustomAutoComplete
      label={label}
      onInputChange={handleInputChange}
      onChange={handleChange}
      options={extendedOptions}
      loading={loading}
      value={selectedMember || null}
      required
      disabled={disabled}
      error={error}
    />
  );
}

export default MemberAutoComplete;
