import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import themesConfig from "app/configs/themesConfig";
import {
  KYCResultStatusEnum,
  timerToCompleteKYC,
} from "src/app/common/constant";
import { useDispatch, useSelector } from "react-redux";
import {
  eKYCResult,
  eKYCResultForMobile,
  getKYCSDKToken,
  setIseKYCButtonLoading,
  setIseKYCResultSuccess,
  setKYCErrorMessage,
} from "app/store/application-form/applicationFormSlice";
import { useTranslation } from "react-i18next";
import { formatTime } from "src/app/services/utils";
import { useParams } from "react-router";
declare const WAILib: any;

const useStyles = makeStyles((theme) => ({
  previewMessage: {
    color: themesConfig.default.palette.text.secondary,
  },
  captureButton: {
    backgroundColor: themesConfig.default.palette.primary.main,
    color: themesConfig.default.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: themesConfig.default.palette.primary.main,
    },
  },
  retakeButton: {
    backgroundColor: "transparent",
    border: `2px solid ${themesConfig.default.palette.secondary.main}`,
    color: themesConfig.default.palette.common.black,
    "&:hover": {
      borderColor: themesConfig.default.palette.secondary.main,
      backgroundColor: "transparent",
    },
  },
  proceedButton: {
    backgroundColor: themesConfig.default.palette.secondary.main,
    color: themesConfig.default.palette.info.contrastText,
    "&:hover": {
      backgroundColor: themesConfig.default.palette.secondary.main,
    },
  },
}));

const MyKadFinance = ({ showResult, isMobile = false, token = null }) => {
  const { t } = useTranslation("user");
  const classes = useStyles();
  const params = useParams();
  const { applicationId } = params;
  const dispatch: any = useDispatch();
  const SDKKeyForKYC = useSelector(getKYCSDKToken);
  const [isTimerStart, setIsTimerStart] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number>(
    timerToCompleteKYC * 60 * 1000
  );

  useEffect(() => {
    if (!isTimerStart) {
      // Start timer countdown when component is mounted
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime > 1000) {
            return prevTime - 1000;
          } else {
            window.location.reload(); // Reload the page when timeLeft reaches 0
            return 0;
          }
        });
      }, 1000);

      // Clear timer when component is unmounted to prevent memory leaks
      return () => clearInterval(timer);
    }
  }, [isTimerStart]);

  // Format the time as MM:SS
  const remainingTime = formatTime(timeLeft);

  useEffect(() => {
    if (typeof WAILib !== "undefined") {
      try {
        if (SDKKeyForKYC) {
          const wiseai = new WAILib.SDK(
            SDKKeyForKYC,
            process.env.REACT_APP_WISE_AI_API_URL,
            "myDiv",
            {
              docType: "mykad",
              cameraFit: true,
              enableIDFraudDetection: true,
              actionButtons: {
                cameraCapture: {
                  css: `${classes.captureButton} border-0 cursor-pointer`,
                  elementId: "",
                },
                retake: {
                  text: t("RETAKE"),
                  css: `${classes.retakeButton} text-16 rounded-8 cursor-pointer`,
                },
                proceed: {
                  text: t("PROCEED"),
                  css: `${classes.proceedButton} text-16 border-0 rounded-8 cursor-pointer`,
                },
              },
              stages: {
                mykadFront: {
                  title: t("FRONT_IC"),
                  message: t("FRONT_TITLE_MESSAGE"),
                  titleCss: "mt-40",
                  messageCss: "text-14",
                  preview: {
                    message: t("FRONT_PREVIEW_MESSAGE"),
                    messageCss: `${classes.previewMessage} text-16 text-center`,
                  },
                },
                mykadBack: {
                  title: t("BACK_IC"),
                  message: t("BACK_TITLE_MESSAGE"),
                  titleCss: "mt-40",
                  messageCss: "text-14",
                  preview: {
                    message: t("BACK_PREVIEW_MESSAGE"),
                    messageCss: `${classes.previewMessage} text-16 text-center`,
                  },
                },
                mykadFace: {
                  title: t("SELFIE"),
                  message: t("SELFIE_TITLE_MESSAGE"),
                  titleCss: "mt-40",
                  messageCss: "text-14",
                  video: {
                    readyCountdownText: t("SELFIE_PREVIEW_MESSAGE"),
                  },
                },
              },
              liveness: {
                feedbacks: {
                  getReadyText: t("GET_READY_TEXT"),
                  holdPositionText: t("HOLD_POSITION_TEXT"),
                  centerFaceText: t("CENTER_FACE_TEXT"),
                  moveCloserText: t("MOVE_CLOSERtEST"),
                  moveAwayText: t("MOVE_AWAY_TEXT"),
                },
              },
            },
            "/wailib-js-sdk/models"
          );

          wiseai.startCamera();
          wiseai.cameraCapture({
            onSuccess: function (stage, result) {
              dispatch(setIseKYCButtonLoading(true));
              const payload = {
                status: KYCResultStatusEnum.success,
                stage: stage,
                financeApplicationId: applicationId,
                result: result,
              };
              if (isMobile) {
                dispatch(eKYCResultForMobile(payload, token));
              } else {
                dispatch(eKYCResult(payload));
              }
              showResult();
            },
            onError: function (stage, error) {
              console.error("error");
              dispatch(
                eKYCResult({
                  status: KYCResultStatusEnum.error,
                  stage: stage,
                  message: error,
                  financeApplicationId: applicationId,
                })
              );
              dispatch(setIseKYCResultSuccess(false));
              showResult();
              setIsTimerStart(true);
            },
          });
        } else {
          showResult();
          dispatch(setIseKYCResultSuccess(false));
          dispatch(setKYCErrorMessage(t("SDK_NOT_GET_ERROR")));
          setIsTimerStart(true);
        }
      } catch (error) {
        console.error("SDK initialization error:", error);
        dispatch(setIseKYCResultSuccess(false));
        dispatch(setKYCErrorMessage(t("SDK_ERROR")));
        showResult();
        setIsTimerStart(true);
      }
    } else {
      console.error("WAILib is not loaded.");
      dispatch(setKYCErrorMessage(t("WEB_LIB_ERROR")));
      showResult();
      setIsTimerStart(true);
    }
  }, [dispatch, classes]);

  return (
    <>
      {timeLeft !== null && (
        <div className="relative w-full h-full">
          <div id="myDiv" className="w-full h-full"></div>
          {!isTimerStart && (
            <div className="text-16 text-center mb-10 text-white bg-grey-500 absolute top-7 right-7 px-10 py-5 rounded-8">
              {t("TIME_REMAINING")}: {remainingTime}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MyKadFinance;
