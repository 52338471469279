import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Box,
  Paper,
  Card,
  Input,
  CircularProgress
} from "@mui/material";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import styled from "styled-components";
import themesConfig from "app/configs/themesConfig";
import { useTranslation } from "react-i18next";
import { acceptFileTypeImageAndPDF, tenantId } from "src/app/common/constant";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import { useParams } from "react-router";
import {
  fileUpload,
  getApplicationCommunicationChat,
  getChatListPaginationData,
  getCurrentFinanceApplicationStatus,
  getFinanceApplicationCommunication,
  getIsSendMessageLoading,
  getLoginUserData,
  getSelectedDocumentPath,
  getUserData,
  getUserId,
  selectUser,
  sendFinanceApplicationCommunication,
  setChatListPagination,
  setSelectedDocumentPath,
  resetCommunicationChatList,
  getIsChatListLoading
} from "app/store/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import { hasPermission } from "src/app/services/utils";
import ErrorMessage from "src/app/services/FeaturePermission/ErrorMessage";
import PreviewMedia from "app/shared-components/Dialog/PreviewMedia";
import { StyledTypographyGrey } from "app/shared-components/Styles/CustomStyle";
import FilePreview from "app/shared-components/preview-file/FilePreview";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import { isEmpty, throttle } from "lodash";

interface MessageContainerProps {
  sender: string;
  personaldataname: string;
}

const StyledBox = styled(Box)<MessageContainerProps>`
  border: 1px solid ${() => themesConfig.default.palette.divider};
  background-color: ${() => themesConfig.default.palette.common.white};
  color: ${() => themesConfig.legacy.palette.common.black};
  border-radius: ${({ sender, personaldataname }) =>
    sender === personaldataname ? "15px 0px 15px 15px" : "0px 15px 15px 15px"};
  margin: 5px;
  padding: 10px 15px;
  display: flex;
  word-wrap: anywhere;

  img {
    width: 300px;
    height: 150px;
    object-fit: contain;
    border-radius: inherit;
  }
`;

const StyledAvatar = styled.div<MessageContainerProps>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${themesConfig.default.palette.primary.light};
  color: ${themesConfig.default.palette.common.white};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({ sender, personaldataname }) =>
    sender === personaldataname ? "10px" : "0"};
`;

const StyledDiv = styled.div<MessageContainerProps>`
  display: flex;
  justify-content: ${({ sender, personaldataname }) =>
    sender === personaldataname ? "flex-end" : "flex-start"};
  // margin-bottom: 10px;
`;
const StyledTypographyGray = styled(Typography)(({ theme }) => ({
  color: themesConfig.default.palette.primary.dark,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: themesConfig.default.palette.common.black,
}));

const StyledFilePreview = styled(Paper)`
  position: relative;
  max-width: 300px;
  padding: 10px;
  z-index: 1;
  margin: 0 30px;
  display: inline-block;

  img {
    width: 100%;
    max-height: 150px;
    border-radius: inherit;
  }
`;

const CancelIconButton = styled(IconButton)`
  position: absolute;
  top: 5px; // Adjust as needed
  right: 5px; // Adjust as needed
`;

// Make the message container scrollable without a fixed height
const MessageContainer = styled(Box)`
  height: calc(100vh - 400px);
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px;
  position: relative; 
`;


function ChatWindow() {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const userId = useSelector(getUserId);
  const { applicationId } = useParams<{ applicationId: string }>();
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const chatList = useSelector(getApplicationCommunicationChat);
  const filePath = useSelector(getSelectedDocumentPath);
  const isSendMessageLoading = useSelector(getIsSendMessageLoading);
  const currentFinanceStatus = useSelector(getCurrentFinanceApplicationStatus);
  const chatListPagination = useSelector(getChatListPaginationData);
  const [isInitialLoad, setIsInitialLoad] = useState(true); // using state to track initial load
  const isLoading = useSelector(getIsChatListLoading);

  if (!hasPermission(FeaturePermissions.FinanceApplicationCommunication.List)) {
    return (
      <ErrorMessage errorMessage="NOT_ALLOW_VIEW_COMMUNICATION"></ErrorMessage>
    );
  }
  const messageContainerRef = useRef<HTMLDivElement>(null); // Ref for the scroll container
  const isPermissionToCreateCommunication = hasPermission(
    FeaturePermissions.FinanceApplicationCommunication.Create
  );


  // to fetch data once when the component mounts
  useEffect(() => {
    if (isEmpty(chatList)) {
      dispatch(getFinanceApplicationCommunication(applicationId));
    }

    // resets the chat list when component unmounts 
    return () => dispatch(resetCommunicationChatList());
  }, [dispatch]);

  // to call api every 30 seconds
  useEffect(() => {
    if (chatListPagination?.pageIndex === 0) {
      const intervalId = setInterval(() => {
        dispatch(getFinanceApplicationCommunication(applicationId));
      }, 30000);
      return () => clearInterval(intervalId);
    }
  }, [chatListPagination]);


  useEffect(() => {
    const handleScroll = throttle(() => {
      if (!messageContainerRef.current) return;

      const { scrollTop } = messageContainerRef.current;
      if (scrollTop === 0) {
        // Fetch more messages on scroll to top
        if (chatListPagination.totalCount > chatList.length) {
          dispatch(
            setChatListPagination({
              pageIndex: chatListPagination.pageIndex + 1,
              pageSize: chatListPagination.pageSize,
            })
          );
          dispatch(getFinanceApplicationCommunication(applicationId));
        }
      }
    }, 1000); // Throttled scroll handling

    const scrollToBottom = () => {
      if (messageContainerRef.current) {
        messageContainerRef.current.scrollTop =
          messageContainerRef.current.scrollHeight;
      }
    };

    if (isInitialLoad && messages.length > 0) {
      // Scroll to bottom only on initial load
      scrollToBottom();
      setIsInitialLoad(false); // Set to false after initial scroll
    }

    const pageNode = messageContainerRef.current;
    if (pageNode) {
      pageNode.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (pageNode) {
        pageNode.removeEventListener("scroll", handleScroll);
      }
    };
  }, [dispatch, chatList, chatListPagination, messages]);



  useEffect(() => {
    return () => {
      dispatch(setSelectedDocumentPath(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (filePath) {
      setSelectedFile(filePath);
    }
  }, [filePath]);

  useEffect(() => {
    setMessages(chatList);
  }, [chatList]);

  const handleMessageSend = () => {
    if (inputText.trim() || selectedFile) {
      const newMessage = {
        tenantId: tenantId,
        userId: userId,
        financeApplicationId: applicationId,
        isMessageFromUser: true,
        isMessageFromTenant: true,
        message: inputText,
        messageFilePath: selectedFile,
      };

      dispatch(sendFinanceApplicationCommunication(newMessage)).then(
        (success) => {
          if (success) {
            setInputText("");
            setSelectedFile(null);
          }
        }
      );
      dispatch( // resets pagination to initial state
        setChatListPagination({
          pageIndex: 0,
          pageSize: 20,
        })
      );
    }
  };

  const handleAttachmentClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];

    if (file) {
      dispatch(fileUpload(file));
    }
  };

  const handleRemove = () => {
    setSelectedFile(null);
  };

  const renderMessageContent = (message) => {
    return (
      <>
        <div>
          {message.messageFilePath && (
            <Paper variant="outlined" className="p-6 mb-10 flex items-start">
              <>
                <PreviewMedia filePath={message.messageFilePath}>
                  <FilePreview fileUrl={message.messageFilePath} />
                </PreviewMedia>
                <IconButton
                  className="top-0 right-0"
                  onClick={() => window.open(message.messageFilePath, "_blank")}
                >
                  <DownloadIcon />
                </IconButton>
              </>
            </Paper>
          )}
          {message.message && (
            <StyledTypography variant="body2">
              {message.message}
            </StyledTypography>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Grid
        container
        className="p-10"
        sx={{ backgroundColor: themesConfig.default.palette.primary.main }}
      >
        <MessageContainer ref={messageContainerRef}>
          {isLoading && (
            <Box className="flex justify-center items-center absolute top-0 w-full z-10">
              <CircularProgress  size={24} color="secondary"/>
            </Box>
          )}
          {messages?.length > 0 &&
            messages?.map((message, index) => (
              <Grid item xs={12} key={index} style={{ paddingTop: 0 }}>
                <StyledDiv sender={userId} personaldataname={message.userId}>
                  <div className="max-w-[700px]">
                    <div className="flex flex-col mb-4">
                      <div className="flex justify-between gap-20 items-center">
                        <div className="flex justify-center items-center gap-6 mb-2">
                          <StyledAvatar
                            sender={userId}
                            personaldataname={message.userId}
                          >
                            {message.userName.charAt(0).toUpperCase()}
                          </StyledAvatar>
                          <StyledTypography className="text-12">
                            {message.userName}
                          </StyledTypography>
                        </div>
                        <StyledTypographyGray className="text-12">
                          {message.created}
                        </StyledTypographyGray>
                      </div>
                      <StyledBox
                        sender={userId}
                        personaldataname={message.userId}
                      >
                        {renderMessageContent(message)}
                      </StyledBox>
                    </div>
                  </div>
                </StyledDiv>
              </Grid>
            ))}
        </MessageContainer>
        {isPermissionToCreateCommunication &&
          currentFinanceStatus !== "rejected" && (
            <Grid item xs={12}>
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                accept={acceptFileTypeImageAndPDF}
                onChange={handleFileChange}
              />
              <Card className="w-full overflow-hidden">
                <Input
                  className="p-24 w-full"
                  classes={{ root: "text-14" }}
                  placeholder="Write something.."
                  multiline
                  rows="4"
                  margin="none"
                  disableUnderline
                  value={inputText}
                  disabled={isSendMessageLoading}
                  onChange={(e) => setInputText(e.target.value)}
                />
                {selectedFile && (
                  <StyledFilePreview variant="outlined">
                    <CancelIconButton
                      onClick={handleRemove}
                      disabled={isSendMessageLoading}
                    >
                      <CancelIcon />
                    </CancelIconButton>
                    <FilePreview fileUrl={selectedFile}></FilePreview>
                  </StyledFilePreview>
                )}
                <Box className="card-footer flex items-center flex-row border-t-1 px-24 py-12">
                  <div className="flex flex-1 items-center">
                    <IconButton
                      onClick={handleAttachmentClick}
                      disabled={isSendMessageLoading}
                    >
                      <AttachFileOutlinedIcon />
                    </IconButton>
                    <StyledTypographyGrey
                      variant="body2"
                      className="text-12 font-400 italic tracking-wide"
                    >
                      ({t("SUPPORT_FILE")}: {acceptFileTypeImageAndPDF})
                    </StyledTypographyGrey>
                  </div>

                  <div>
                    <ColoredSubmitButton
                      onClick={handleMessageSend}
                      disabled={!inputText.trim() && !selectedFile}
                      text={t("SEND")}
                      isLoading={isSendMessageLoading}
                    />
                  </div>
                </Box>
              </Card>
            </Grid>
          )}
      </Grid>
    </>
  );
}

export default ChatWindow;
