import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import _ from "@lodash";
import { FormGroup, Grid, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import withReducer from "app/store/withReducer";
import { residentialLevel, tenantId } from "src/app/common/constant";
import { useTranslation } from "react-i18next";
import CustomTextField from "app/shared-components/Form/CustomTextField";
import {
  addressUpload,
  getMemberUserId,
  getUserId,
  selectIsButtonLoading,
  updateUserAddress,
  userSlice,
} from "app/store/user/userSlice";
import CustomCheckbox from "app/shared-components/Form/CustomCheckbox";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";

interface FormModel {
  houseAddress: string;
  houseCity: string;
  houseState: string;
  housePostalCode: string;
  mailingAddress: string;
  mailingCity: string;
  mailingState: string;
  mailingPostalCode: string;
  houseStatus: string;
  otherResidentialLevel: string;
}

const defaultValues: FormModel = {
  houseAddress: "",
  houseCity: "",
  houseState: "",
  housePostalCode: "",
  houseStatus: "",
  mailingAddress: "",
  mailingCity: "",
  mailingState: "",
  mailingPostalCode: "",
  otherResidentialLevel: "",
};

function AddressForm({
  userAddress,
  setIsDirty = (value) => {},
  showFinishButton = false,
  isUseForFinance = false
}) {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const memberId = useSelector(getMemberUserId);
  const userId = useSelector(getUserId);
  const isButtonLoading = useSelector(selectIsButtonLoading);

  const schema = yup.object().shape({
    houseAddress: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("HOUSE_ADDRESS")})),
    housePostalCode: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("POST_CODE")})),
    houseStatus: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("HOUSE_STATUS")})),
    same: yup.boolean(),
    houseCity: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("CITY")})),
    mailingCity: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("MAILING_CITY")})),
    mailingAddress: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("MAILING_ADDRESS")})),
    mailingPostalCode: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("MAILING_POST_CODE")})),
    houseState: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("STATE")})),
    mailingState: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("MAILING_STATE")})),
    otherResidentialLevel: yup
      .string()
      .when(["houseStatus"], (houseStatus, schema) => {
        if (houseStatus[0] === "Other") {
          return schema.required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("OTHER")}));
        }
        return schema.notRequired();
      }),
  });

  const methods = useForm<any>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    control,
    formState,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
  } = methods;
  const { isValid, errors, isDirty } = formState;

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  useEffect(() => {
    // Set default values in the form fields when UserAddress changes
    if (userAddress) {
      Object.keys(userAddress).forEach((key) => {
        setValue(key, userAddress[key]);
      });
      const houseStatusValue = userAddress?.houseStatus || "";
      if (!residentialLevel.map((n) => n.id).includes(houseStatusValue)) {
        setValue("houseStatus", "Other");
        setValue("otherResidentialLevel", houseStatusValue || "");
      } else {
        setValue("houseStatus", houseStatusValue);
        setValue("otherResidentialLevel", "");
      }
    }
  }, [userAddress, setValue]);

  const onSubmit = (model: FormModel) => {
    let houseStatusValue = model.houseStatus;

    if (model.houseStatus === "Other") {
      houseStatusValue = model.otherResidentialLevel;
    }
    if (!userAddress?.id) {
      const request_data = {
        ...model,
        houseStatus: houseStatusValue,
        tenantId: tenantId,
        userId: memberId || userId,
      };
      dispatch(addressUpload(request_data, isUseForFinance));
    } else {
      const formData = {
        ...model,
        houseStatus: houseStatusValue,
        id: userAddress?.id,
      };
      dispatch(updateUserAddress(formData));
    }
  };

  const handleNext = () => {
    handleSubmit(onSubmit)();
  };

  const handleSameChange = (same) => {
    // if mailing address is checked fill the mailing address field with house number
    if (same) {
      setValue("mailingAddress", methods.getValues("houseAddress"));
      setValue("mailingCity", methods.getValues("houseCity"));
      setValue("mailingPostalCode", methods.getValues("housePostalCode"));
      setValue("mailingState", methods.getValues("houseState"));
    } else {
      // If not checked, clear the mailing address
      setValue("mailingAddress", "");
      setValue("mailingCity", "");
      setValue("mailingPostalCode", "");
      setValue("mailingState", "");
    }
  };

  return (
    <Box>
      <Paper variant="outlined">
        <FormProvider {...methods}>
          <form
            className="flex flex-col justify-center w-full p-20"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={2}>
              {/* houseAddress */}
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="houseAddress"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("HOUSE_ADDRESS")}
                      value={field.value}
                      onChange={field.onChange}
                      error={errors?.houseAddress?.message?.toString() || ""}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>

              {/* house city */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="houseCity"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("HOUSE_CITY")}
                      value={field.value || ""}
                      fullWidth
                      required
                      onChange={field.onChange}
                      error={errors?.houseCity?.message?.toString() || ""}
                    />
                  )}
                />
              </Grid>

              {/* house state */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="houseState"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("HOUSE_STATE")}
                      value={field.value || ""}
                      fullWidth
                      required
                      onChange={field.onChange}
                      error={errors?.houseState?.message?.toString() || ""}
                    />
                  )}
                />
              </Grid>

              {/* house postal code */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="housePostalCode"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("POST_CODE")}
                      value={field.value}
                      fullWidth
                      required
                      onChange={field.onChange}
                      error={errors?.housePostalCode?.message?.toString() || ""}
                    />
                  )}
                />
              </Grid>

              {/* is mailing address checkbox */}
              <Grid item xs={12}>
                <Controller
                  name="same"
                  control={control}
                  render={({ field }) => (
                    <CustomCheckbox
                      label={t("SAME_ADDRESS")}
                      onChange={() => {
                        field.onChange(!field.value);
                        handleSameChange(!field.value);
                      }}
                    />
                  )}
                />
              </Grid>

              {/* mailing address */}
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="mailingAddress"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("MAILING_ADDRESS")}
                      fullWidth
                      required
                      value={field.value}
                      // value={isMailingAddress ? methods.watch('houseNumber') : field.value} // Fill with houseNumber if isMailingAddress is true
                      onChange={field.onChange}
                      error={errors?.mailingAddress?.message?.toString() || ""}
                    />
                  )}
                />
              </Grid>

              {/* mailing city */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="mailingCity"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("MAILING_CITY")}
                      fullWidth
                      required
                      value={field.value || ""}
                      onChange={field.onChange}
                      error={errors?.mailingCity?.message?.toString() || ""}
                    />
                  )}
                />
              </Grid>

              {/* mailing state */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="mailingState"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("MAILING_STATE")}
                      fullWidth
                      required
                      value={field.value || ""}
                      onChange={field.onChange}
                      error={errors?.mailingState?.message?.toString() || ""}
                    />
                  )}
                />
              </Grid>

              {/* mailing postal code */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="mailingPostalCode"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("POST_CODE")}
                      fullWidth
                      required
                      value={field.value}
                      onChange={field.onChange}
                      error={
                        errors?.mailingPostalCode?.message?.toString() || ""
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} className="mb-8">
                <Grid container spacing={2} className="flex items-center">
                  <Grid item xs={12} sm={8}>
                    <FormGroup className="flex flex-row justify-start items-center">
                      <Typography variant="body1" className="mr-40">
                        {t("RESIDENTIAL_LEVEL")}*
                      </Typography>
                      <Controller
                        name="houseStatus"
                        control={control}
                        defaultValue={userAddress?.houseStatus || ""}
                        render={({ field }) => (
                          <>
                            {residentialLevel.map((item) => (
                              <CustomCheckbox
                                key={item.id}
                                label={t(item.label)}
                                onChange={() => field.onChange(item.id)}
                                checked={field.value === item.id}
                              />
                            ))}
                          </>
                        )}
                      />
                    </FormGroup>
                    {errors.houseStatus && (
                      <Typography
                        variant="subtitle2"
                        fontSize={"1.2rem"}
                        color="error"
                      >
                        {t(errors?.houseStatus?.message.toString())}
                      </Typography>
                    )}
                  </Grid>
                  {watch("houseStatus") === "Other" && (
                    <Grid item xs={12} sm={4} className="mb-8">
                      <Controller
                        name="otherResidentialLevel"
                        control={control}
                        render={({ field }) => (
                          <CustomTextField
                            label={t("OTHER")}
                            fullWidth
                            required
                            value={field.value}
                            onChange={field.onChange}
                            error={
                              errors.otherResidentialLevel &&
                              t(
                                errors?.otherResidentialLevel?.message.toString()
                              )
                            }
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* Navigation Buttons */}
            <Box sx={{ mt: 2 }} className={userAddress ? "text-right" : ""}>
              <div>
                <ColoredSubmitButton
                  onClick={handleNext}
                  disabled={!isValid || isButtonLoading || !isDirty}
                  isLoading={isButtonLoading}
                  text={
                    showFinishButton
                      ? t("FINISH")
                      : !userAddress
                      ? t("SAVE_AND_CONTINUE")
                      : t("SAVE")
                  }
                />
              </div>
            </Box>
          </form>
        </FormProvider>
      </Paper>
    </Box>
  );
}

export default withReducer("user", userSlice.reducer)(AddressForm);
