import * as React from "react";
import {
  Box,
  Button,
} from "@mui/material";
import {
  StyledTypography,
} from "app/shared-components/Styles/CustomStyle";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCurrentStep,
  getExistingFinanceDetails,
  getExistingFinanceDetailsData,
  selectIsButtonLoading,
  setCurrentStep,
  setStepCompletionStatus,
} from "app/store/application-form/applicationFormSlice";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import ExistingFinanceTable from "../../user/component/finance-detail-page/ExistingFinanceTable";

const ExistingLoans = () => {
  const { t } = useTranslation("applicationForm");
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [search] = useSearchParams();
  const financeId = search.get("id") || null;
  const userId = search.get("userId") || null;
  const activeStep = useSelector(getCurrentStep);
  const loans = useSelector(getExistingFinanceDetailsData);
  const isLoading = useSelector(selectIsButtonLoading);

  React.useEffect(() => {
    if (financeId) {
      dispatch(getExistingFinanceDetails(financeId));
    }
  }, [dispatch, financeId]);

  const handleNext = () => {
    navigate(
      `/application-form/${activeStep + 1}?userId=${userId}&id=${financeId}`
    );
    dispatch(setStepCompletionStatus({ index: activeStep, isComplete: true }));
  };

  const handleBack = () => {
    dispatch(setCurrentStep(activeStep - 1));
    navigate(
      `/application-form/${activeStep - 1}?userId=${userId}&id=${financeId}`
    );
  };

  return (
    <>
      <StyledTypography variant="body1" className="mb-16">
        {t("EXISTING_LOAN")}
      </StyledTypography>
      <div className="w-full overflow-auto">
        <ExistingFinanceTable financeDetail={loans} financeId={financeId} />
      </div>
      <Box>
        <Box className="flex justify-end items-end gap-20 mt-10">
          <Button
            variant="outlined"
            className="capitalize w-120"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {t("PREVIOUS")}
          </Button>
          <ColoredSubmitButton
            className="w-120"
            text={t("NEXT")}
            onClick={handleNext}
            isLoading={isLoading}
          ></ColoredSubmitButton>
        </Box>
      </Box>
    </>
  );
};

export default ExistingLoans;
